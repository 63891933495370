<template>
  <div class="outLineBox" ref="outlineBox">
    <audio
      id="myAudio"
      src="https://suezp.cn/server//bgm.mp3"
      ref="audio"
      loop
    ></audio>
    <div
      :class="['audioContral', { audioContralRotate: playAudio }]"
      v-if="!showLoading"
      @click="controlPlay"
    >
      <img src="https://suezp.cn/server//music.png" alt="音乐按钮" />
    </div>
    <transition name="fade">
      <Loading
        class="loadingDiv"
        @done="loadingDone"
        :progress="loadingProgress"
        v-if="showLoading"
      ></Loading>
    </transition>
    <!-- 横屏提醒 -->
    <RotateTips
      v-if="phoneOrientation == 0"
      class="loadingDiv rotateTipsDiv"
    ></RotateTips>
    <!-- 背景介绍 -->
    <transition name="fade">
      <background-story
        class="loadingDiv"
        @bgsDone="showBgs = false"
        v-if="!showLoading && showBgs"
      ></background-story>
    </transition>
    <!-- 会议室模块 -->
    <transition name="fade">
      <meeting-room
        class="firstBlock"
        v-if="!showLoading && nonClick && !showBgs"
        @clickRole="clickRole"
        @showPeoples="showPeople"
      ></meeting-room>
    </transition>
    <!-- 围观群众 -->
    <transition name="fade">
      <div class="people" v-if="showPeoples">
        <span class="people people1">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people2">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people3">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people4">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people5">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people6">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people7">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people8">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people9">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people10">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people11">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people12">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people13">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people14">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people15">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
        <span class="people people16">
          <img :src="imgUrl + 'peoples.png'" alt="" />
        </span>
      </div>
    </transition>
    <!-- 游戏主体模块 -->
    <!-- TODO 已经完善 -->
    <transition name="fade">
      <div
        class="contentBox"
        v-if="!showLoading && !nonClick && showMain"
        @click="nextSaying"
      >
        <ul :style="{ marginLeft: leftMargin + 'px' }">
          <!-- 跑图背景 -->
          <!-- 展台1 -->
          <li :style="{ width: phoneWidth + 'px' }">
            <img :src="imgUrl + 'wall_1.png'" alt="展台2" class="bgImg" />
            <viewer :images="imgList1" class="imgCover1">
              <img
                v-for="(src, index) in imgList1"
                :src="src"
                :key="index"
                @click="viewImg1"
              />
            </viewer>
          </li>
          <!-- 展台2 -->
          <li :style="{ width: phoneWidth + 'px' }">
            <img
              :src="imgUrl + 'wall_2.png'"
              v-if="!showMetting"
              class="bgImg"
              alt="展台2"
            />
            <img
              :src="imgUrl + 'meetingRoom.png'"
              v-if="showMetting"
              class="bgImg"
              alt="展台2"
            />
            <div
              :class="['human', { humanLeave: humanLeave }]"
              v-if="!showMetting"
            >
              <common-human
                style="left: -22px"
                class="person"
                ref="oldFather"
                :scaleX="1"
                :modal="oldFatherModal"
                :words="oldFatherWords"
                :moving="oldFatherMoving || humanLeave"
                :nextArrow="oldFatherNextArrow"
                imgUrl="https://suezp.cn/server//oldFather.png"
                :clickArrow="oldFatherArrow"
                :humanStyle="'height:180px'"
                @click="clickOldfather"
              ></common-human>
              <common-human
                class="person"
                ref="sister2"
                style="margin-right: 80px"
                :scaleX="1"
                :modal="sister2Modal"
                :words="sister2words"
                :moving="sister2Moving || humanLeave"
                imgUrl="https://suezp.cn/server//sister2.png"
                :clickArrow="sister2Arrow"
                :nextArrow="sister2NextArrow"
                :humanStyle="'transofrm:scaleX(-1);height:140px'"
                @click="clickSister2"
              ></common-human>
              <common-human
                style="left: -63px"
                class="person"
                ref="sister1"
                :scaleX="1"
                :modal="sister1Modal"
                :words="sister1words"
                :moving="sister1Moving || humanLeave"
                imgUrl="https://suezp.cn/server//sister1.png"
                :clickArrow="sister1Arrow"
                :nextArrow="sister1NextArrow"
                :humanStyle="'transofrm:scaleX(-1);height:150px'"
                @click="clickSister1"
              ></common-human>
            </div>
            <viewer :images="imgList2" class="imgCover2" v-if="!showMetting">
              <img
                v-for="(src, index) in imgList2"
                :src="src"
                :key="index"
                @click="viewImg2"
              />
            </viewer>
            <div class="counter2" v-if="!showMetting">
              <ul>
                <li v-for="item in counter2List" :key="item.key">
                  <viewer class="imgViewer">
                    <img :src="item.src" @click="clickCounter2(item.key)" />
                  </viewer>
                  <span class="imgArrow" v-show="item.show"> 👇 </span>
                </li>
              </ul>
            </div>
          </li>
          <!-- 展台3 -->
          <li :style="{ width: phoneWidth + 'px' }">
            <img :src="imgUrl + 'wall_3.png'" alt="展台3" class="bgImg" />
            <!-- <common-human
              style="left: -63px"
              class="person"
              ref="sister1"
              :scaleX="1"
              :modal="sister1Modal"
              :words="sister1words"
              :moving="sister1Moving"
              imgUrl="https://suezp.cn/server//sister1.png"
              :clickArrow="sister1Arrow"
              :nextArrow="sister1NextArrow"
              :humanStyle="'transofrm:scaleX(-1);height:150px'"
              @click="clickSister1"
            ></common-human> -->
            <viewer :images="imgList3" class="imgCover3" v-if="showViewer3">
              <img
                v-for="(src, index) in imgList3"
                :src="src"
                :key="index"
                @click="viewImg3(index)"
              />
            </viewer>
            <span class="imgArrow" style="top: 5%; left: 10%" v-if="station3_1">
              👇
            </span>
            <span class="imgArrow" style="top: 5%; left: 31%" v-if="station3_2">
              👇
            </span>
            <span class="imgArrow" style="top: 5%; left: 61%" v-if="station3_3">
              👇
            </span>
          </li>
        </ul>
        <!-- 主角 -->
        <div
          :class="[
            'mainRole',
            { hideMainRole: hideMainRole },
            { hideMainRoleDone: hideMainRoleDone },
          ]"
          :style="{ left: mainRoleLeft + '%' }"
        >
          <main-role
            ref="mainRole"
            :scaleX="scaleX"
            :modal="mainRoleModal"
            :moving="MainRoleMoving"
            :words="mainRoleWords"
            :nextArrow="mainRoleNextArrow"
            :exclamation="mainRoleExclamation"
            :scare="mainRoleScare"
            @click="clickMainRole"
          ></main-role>
        </div>
        <!-- 现代主角 -->
        <transition name="fade">
          <div
            class="recentRole"
            :style="{ left: recentRoleLeft + '%' }"
            v-if="showRecentRole"
          >
            <recent-role
              ref="recentRole"
              class="recentRole"
              :scaleX="recentScaleX"
              :modal="recentRoleModal"
              :words="recentRoleWords"
              :moving="RecentRoleMoving"
              :nextArrow="recentRoleNextArrow"
            >
            </recent-role>
          </div>
        </transition>
        <!-- 点击图片按钮 -->
        <div v-if="showImgArrow">
          <span class="imgArrow"> 👇 </span>
          <span class="imgArrow imgArrow2"> 👇 </span>
          <span class="imgArrow imgArrow3"> 👇 </span>
        </div>
        <div v-if="showGoRight">
          <span class="goRightArrow"> 👉 </span>
        </div>
        <!-- 底部按钮 -->
        <div class="btnBox">
          <!-- <span></span> -->
          <span
            @touchstart="goLeft(true)"
            @touchend="goLeft(false)"
            @click="clickLeft"
          >
            👈
          </span>
          <span
            @touchstart="goRight(true)"
            @touchend="goRight(false)"
            @click="clickRight"
          >
            👉
          </span>
          <!-- <span></span> -->
        </div>
        <!-- 拍照动效果 -->
        <div class="camera" v-if="showCamera"></div>
        <!-- 展示图片 -->
        <transition name="fade">
          <div class="photo" v-if="showPhotos" @click="showPhotos = false">
            <img
              class="photoImg"
              src="https://suezp.cn/server//photo1.png"
              alt="合照"
            />
            <span class="goRightNext"> 👉 </span>
          </div>
        </transition>
        <!-- 梦醒层 -->
        <transition name="fade">
          <div class="dream" v-if="showDream"></div>
        </transition>
        <!-- 图片展示层 -->
        <transition name="fade">
          <div
            class="photo"
            style="z-index: 10000"
            v-if="showFinal"
            @click="finalClick"
          >
            <img
              class="photoImg"
              v-if="finalImg == 0"
              src="https://suezp.cn/server//final1.png"
              alt="合照"
            />
            <img
              class="photoImg"
              v-if="finalImg == 1"
              src="https://suezp.cn/server//final2.png"
              alt="合照"
            />
            <img
              class="photoImg"
              v-if="finalImg == 2"
              src="https://suezp.cn/server//final3.png"
              alt="合照"
            />
            <img
              class="photoImg"
              v-if="finalImg == 3"
              src="https://suezp.cn/server//final4.png"
              alt="合照"
            />
            <h3
              v-if="finalImg > 3"
              style="
                color: #fff;
                position: absolute;
                top: 2%;
                text-align: center;
                left: 12%;
                transform: translateX(-50%);
              "
            >
              完结.
            </h3>
            <div :class="['motoDiv', 'rollingMove', { ending: ending }]">
              <img
                class="moto"
                src="https://suezp.cn/server//moto.png"
                alt="车架"
              />
              <span class="circle circle1">
                <img src="https://suezp.cn/server//circle.png" alt="车轮" />
              </span>
              <span class="circle circle2">
                <img src="https://suezp.cn/server//circle.png" alt="车轮" />
              </span>
              <img src="https://suezp.cn/server//gas.png" alt="尾气" class="gas" />
            </div>
            <transition name="fade">
              <div class="pictureShow" v-if="finalImg > 3">
                <h3>插画展示</h3>
                <viewer :images="pictureList">
                  <img
                    v-for="item in pictureList"
                    style="width: 120px"
                    :key="item"
                    :src="item"
                    :alt="item"
                  />
                  <img src="https://suezp.cn/server//final1.png" />
                </viewer>
              </div>
            </transition>
            <transition name="fade">
              <div class="designBanner" v-if="finalImg > 3">
                <p>院校： 武汉轻工大学</p>
                <p>指导老师：陈顺龙</p>
                <p>设计： 邹笑宇</p>
                <!-- <p>技术支持 苏智鹏</p> -->
              </div>
            </transition>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import MeetingRoom from "./meetingRoom";
import Loading from "./components/loading";
import MainRole from "./components/mainRole";
import RecentRole from "./components/recentRole";
import RotateTips from "./components/rotateTips";
import BackgroundStory from "./components/backgroundStroy";
import CommonHuman from "./components/commonHuman";
export default {
  name: "MobileGame",
  components: {
    Loading,
    MeetingRoom,
    MainRole,
    RecentRole,
    RotateTips,
    BackgroundStory,
    CommonHuman,
  },
  data() {
    return {
      leftMargin: 0,
      timer: null,
      roleTimer: null,
      scaleX: 1, //判断人物方向
      canMove: true, //控制人物走动按钮
      speed: 1, // 人物行动速度
      mainRoleLeft: 20, // 人物的位置
      recentRoleLeft: 80,
      mainRoleModal: false,
      mainRoleWords: "",
      mainRoleNextArrow: false,
      mainRoleExclamation: false, // 显示感叹号
      mainRoleScare: false,
      recentRoleWords: "",
      recentScaleX: -1,
      showRecentRole: false,
      recentRoleModal: false,
      recentRoleNextArrow: false,
      phoneHeight: 375,
      phoneWidth: 812,
      phoneOrientation: 0,
      showLoading: true, // 展示laoding层
      nonClick: true,
      showMain: false, // 延时展示
      showBgs: true, //背景模块
      playAudio: false,
      showPeoples: false,
      loadingProgress: 0,
      imgUrl: "https://suezp.cn/server//",
      preloadImageList: [
        "moto.png",
        "circle.png",
        "gas.png",
        "mainRole/head.png",
        "mainRole/body.png",
        "mainRole/arm1.png",
        "mainRole/arm2.png",
        "mainRole/leg1.png",
        "mainRole/leg2.png",
        "recentRole/body.png",
        "recentRole/arm1.png",
        "recentRole/arm2.png",
        "recentRole/leg2.png",
        "recentRole/leg1.png",
        "meetingRoom.png",
        "meetingRoom1.png",
        "oldFather.png", // 老父亲
        "sister1.png", // 小姐姐
        "sister2.png", // 小妹妹
        "peoples.png",
        "banner.png",
        "noisy.png",
        "wall_1.png",
        "wall_1_1.png",
        "wall_1_2.png",
        "wall_1_3.png",
        "wall_2_1.png",
        "wall_2_2.png",
        "wall_2_3.png",
        "wall_2_4.png",
        "wall_3.png",
        "wall_3_1.png",
        "wall_3_2.png",
        "wall_3_3.png",
        "wall_3_4.png",
        "cap.png",
        "meetingNote.png",
        "suitcase.png",
        "music.png",
        "final1.png",
        "final2.png",
        "final3.png",
        "final4.png",
      ],
      imgList1: [
        "https://suezp.cn/server//wall_1_1.png",
        "https://suezp.cn/server//wall_1_2.png",
        "https://suezp.cn/server//wall_1_3.png",
      ],
      imgList2: [
        "https://suezp.cn/server//wall_2_1.png",
        "https://suezp.cn/server//wall_2_2.png",
        "https://suezp.cn/server//wall_2_3.png",
        "https://suezp.cn/server//wall_2_4.png",
      ],
      imgList3: [
        "https://suezp.cn/server//wall_3_1.png",
        "https://suezp.cn/server//wall_3_2.png",
        "https://suezp.cn/server//wall_3_3.png",
        "https://suezp.cn/server//wall_3_4.png",
      ],
      pictureList: [
        "https://suezp.cn/server//meetingRoom.png",
        "https://suezp.cn/server//meetingRoom1.png",
        "https://suezp.cn/server//oldFather.png",
        "https://suezp.cn/server//sister1.png",
        "https://suezp.cn/server//sister2.png",
        "https://suezp.cn/server//final4.png",
        "https://suezp.cn/server//final2.png",
        "https://suezp.cn/server//final3.png",
      ],
      MainRoleMoving: false, //人物移动动画
      RecentRoleMoving: false, //人物移动动画
      nowSaying: "", //TODO 记得清空
      saying: false, //主角正在说话
      showImgArrow: false,
      showViewer1: false,
      showViewer2: false,
      showViewer3: true,
      showGoRight: false, //向右边走 标识
      // 故事任务
      oldFatherArrow: false,
      oldFatherMoving: false,
      oldFatherModal: false,
      oldFatherWords: "",
      oldFatherNextArrow: false,
      sister1Arrow: false,
      sister1Moving: false,
      sister1Modal: false,
      sister1words: "",
      sister1NextArrow: false,
      sister2Arrow: false,
      sister2Moving: false,
      sister2Modal: false,
      sister2words: "",
      sister2NextArrow: false,
      counter2List: [
        {
          key: 0,
          show: false,
          src: "https://suezp.cn/server//meetingNote.png",
        },
        {
          key: 1,
          show: false,
          src: "https://suezp.cn/server//homeBook2.png",
        },
        {
          key: 2,
          show: false,
          src: "https://suezp.cn/server//cap.png",
        },
        {
          key: 3,
          show: false,
          src: "https://suezp.cn/server//suitcase.png",
        },
      ],
      // 展台3相关
      station3_1: false,
      station3_2: false,
      station3_3: false,
      // 跑龙套消失
      humanLeave: false,
      showCamera: false,
      showPhotos: false,
      showMetting: false,
      hideMainRole: false,
      hideMainRoleDone: false,
      showDream: false,
      showFinal: false,
      finalImg: 0,
      ending: false,
    };
  },
  watch: {},
  created() {
    // 图片预加载  如果直接执行会阻碍页面的加载，所以采取异步的方法 先让页面在记载出来再加载资源
    setTimeout(() => {
      this.preloadImg();
    }, 100);
  },
  mounted() {
    // 添加屏幕翻转监听器
    this.orientationChange();
    window.addEventListener("orientationchange", this.orientationChange);
    // 监听 键盘事件
    document.addEventListener("keyup", this.keyUp);
    document.addEventListener("keydown", this.keyDown);
    document.getElementById("myAudio").volume = 0.3;
  },
  methods: {
    // r人物移动操作
    goLeft(jud) {
      if (!this.canMove) {
        this.clearLiveInterval();
        return;
      }
      this.clearLiveInterval();
      this.scaleX = -1;
      if (this.leftMargin >= 0) {
        this.leftMargin = 0;
        clearInterval(this.timer);
        this.timer = null;
        return;
      }
      if (jud) {
        this.timer = setInterval(() => {
          // 中断行走
          if (!this.canMove) {
            this.MainRoleMoving = false;
            this.clearLiveInterval();
            this.timer = null;
            return;
          }
          this.MainRoleMoving = true;
          this.leftMargin += this.speed * 30;
          console.log(this.leftMargin);
          if (this.leftMargin >= 0) {
            this.leftMargin = 0;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 200);
      } else {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    goRight(jud) {
      if (!this.canMove) {
        this.clearLiveInterval();
        return;
      }
      this.clearLiveInterval();
      this.scaleX = 1; //左右方向
      if (this.leftMargin - this.speed * 30 < -2 * this.phoneWidth) {
        this.leftMargin = -2 * this.phoneWidth;
        clearInterval(this.timer);
        this.timer = null;
        return;
      }
      if (jud) {
        if (this.leftMargin - this.speed * 30 < -2 * this.phoneWidth) {
          this.leftMargin = -2 * this.phoneWidth;
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        this.timer = setInterval(() => {
          // 中断行走
          if (!this.canMove) {
            this.MainRoleMoving = false;
            this.clearLiveInterval();
            this.timer = null;
            return;
          }

          this.MainRoleMoving = true;
          this.leftMargin -= this.speed * 30;
          if (this.leftMargin - this.speed * 30 < -2 * this.phoneWidth) {
            this.leftMargin = -2 * this.phoneWidth;
            clearInterval(this.timer);
            this.timer = null;
            // this.mainRoleWalk();
          }
        }, 200);
      } else {
        clearInterval(this.timer);
        clearInterval(this.roleTimer);
        this.timer = null;
      }
    },
    clickLeft() {
      if (!this.canMove) return;
      this.clearLiveInterval();
      this.scaleX = -1;
      if (this.leftMargin >= 0) {
        this.leftMargin = 0;
        clearInterval(this.timer);
        return;
      }
      this.leftMargin += 60;
      this.MainRoleMoving = true;
      setTimeout(() => {
        this.MainRoleMoving = false;
      }, 750);
    },
    clickRight() {
      if (!this.canMove) return;
      this.clearLiveInterval();
      this.scaleX = 1;
      if (this.leftMargin - 60 < -2 * this.phoneWidth) {
        this.leftMargin = -2 * this.phoneWidth;
        clearInterval(this.timer);
        return;
      }
      this.leftMargin -= 60;
      this.MainRoleMoving = true;
      setTimeout(() => {
        this.MainRoleMoving = false;
      }, 750);
    },
    // 背景图走完 人物走动
    mainRoleWalk() {
      // 控制人物的位置操作
      if (this.mainRoleLeft < 90) {
        this.roleTimer = setInterval(() => {
          this.mainRoleLeft += 1 * this.speed;
          console.log("mainRoleLeft", this.mainRoleLeft);
          if (this.mainRoleLeft > 90) {
            this.mainRoleLeft = 90;
            clearInterval(this.roleTimer);
            this.roleTimer = null;
          }
        }, 200);
      } else {
        this.mainRoleLeft = 90;
      }
    },
    // 变速
    changeSpeed() {
      if (this.speed * 30 >= 120) {
        this.speed = 1;
      } else {
        this.speed += 1;
      }
    },
    // bgm 控制
    controlPlay() {
      // TODO 上线记得修改控制器
      // this.story1();
      this.playAudio = !this.playAudio;
      if (this.playAudio) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
    // 定时器清空
    clearLiveInterval() {
      this.MainRoleMoving = false;
      if (this.timer !== null) {
        clearInterval(this.timer);
      }
      if (this.roleTimer !== null) {
        clearInterval(this.roleTimer);
      }
    },
    // 屏幕旋转兼容
    orientationChange() {
      this.phoneOrientation = window.orientation;
      this.$nextTick(function () {
        setTimeout(() => {
          this.phoneHeight =
            this.$refs.outlineBox.getBoundingClientRect().height;
          this.phoneWidth = this.$refs.outlineBox.getBoundingClientRect().width;
          console.log(this.phoneHeight, this.phoneWidth, this.phoneOrientation);
          if (this.leftMargin < -2700 + this.phoneWidth + 24) {
            // 不旋转
            this.leftMargin = -2700 + this.phoneWidth + 24;
          }
        }, 500);
      });
    },
    // 图片资源预加载
    preloadImg() {
      let loadIndex = 0;
      this.preloadImageList.forEach((ele) => {
        let img = new Image();
        img.src = this.imgUrl + ele;
        img.onload = () => {
          loadIndex++;
          this.loadingProgress = Math.round(
            (loadIndex / this.preloadImageList.length) * 100
          );
          // console.log("图片加载完成", this.loadingProgress);
          // if (loadIndex >= this.imageList.length) {
          //   this.showLoading = false;
          // }
        };
      });
    },
    // 加载完成
    loadingDone() {
      this.showLoading = false;
      // 背景音乐
      // this.$refs.audio.play();
      // this.playAudio = true;
    },
    // 键盘操作
    keyDown(event) {
      if (event.keyCode == 37) {
        //左箭头
        this.clickLeft();
      } else {
        this.clickRight();
      }
    },
    // 围观群众层级显示
    showPeople(value) {
      this.showPeoples = value;
    },
    // 小兵醒来走了
    clickRole() {
      this.nonClick = false;
      setTimeout(() => {
        this.showMain = true;
        this.story1();
      }, 2500);
    },
    // 查看高清图 展厅1
    viewImg1() {
      if (this.nowSaying != "") return;
      this.nowSaying = "imgArrowDone";
      let timer = setInterval(() => {
        // console.log(document.querySelector(".viewer-in"));
        if (document.querySelector(".viewer-in") == null) {
          this.nowSaying = "say3";
          this.nextSaying();
          clearInterval(timer);
        }
      }, 1000);
    },
    // 展厅2
    viewImg2() {
      console.log("查看图片");
    },
    // 展厅2  展台点击
    clickCounter2(key) {
      console.log(key);
      let timer = null;
      switch (key) {
        case 0:
          if (this.saying) return;
          this.counter2List[key].show = false;
          timer = setInterval(() => {
            console.log("监听中");
            if (document.querySelector(".viewer-in") == null) {
              if (this.nowSaying == "counter2_1") {
                this.nowSaying = "story2_say3";
                this.nextSaying();
              }
              // TODO 正式要去掉
              // this.nowSaying = "story2_say3";
              // this.nextSaying();

              clearInterval(timer);
            }
          }, 1000);
          break;
        case 1:
          if (this.saying) return;
          this.counter2List[key].show = false;
          timer = setInterval(() => {
            console.log("监听中");
            if (document.querySelector(".viewer-in") == null) {
              if (this.nowSaying == "counter2_2") {
                this.nowSaying = "story2_say6";
              }
              clearInterval(timer);
            }
          }, 1000);
          break;
        case 2:
          break;
        case 3:
          break;
        default:
          break;
      }
    },
    // 展厅3
    viewImg3(key) {
      if (key == 0) {
        this.station3_1 = false;
        this.canMove = false;
        let timer = setInterval(() => {
          if (document.querySelector(".viewer-in") == null) {
            this.mainRoleExclamation = true;
            this.nowSaying = "story3";
            clearInterval(timer);
          }
        }, 1000);
      }
      if (key == 1) {
        let timer = setInterval(() => {
          if (document.querySelector(".viewer-in") == null) {
            this.station3_2 = false;
            this.nowSaying = "story3_say4_done";
            this.nextSaying();
            clearInterval(timer);
          }
        }, 1000);
      }
      if (key == 2) {
        if (this.nowSaying != "move3") return;
        let timer = setInterval(() => {
          if (document.querySelector(".viewer-in") == null) {
            this.station3_3 = false;
            this.nowSaying = "story3_say5_done"; //说完了
            this.nextSaying();
            clearInterval(timer);
          }
        }, 1000);
      }
    },
    // 监听走动距离
    startListenMargin(num) {
      this.walkTimer = setInterval(() => {
        console.log("监听距离中");
        if (this.leftMargin < num * this.phoneWidth + 50) {
          clearInterval(this.walkTimer);
          this.showGoRight = false;
          if (num == -1) {
            //走到指定位置 触发剧情
            this.sister2Arrow = true;
          }
          if (num == -2) {
            this.station3_1 = true;
          }
          this.canMove = false;
          this.mainRoleWalk = false;

          console.log("到了到了");
        }
      }, 500);
    },
    // 剧情推进
    // 对话框 TODO
    story1() {
      if (this.saying) return;
      this.canMove = false; //说话不能走
      this.mainRoleModal = true;
      this.mainRoleWords = ".......这是什么地方？？？";
      setTimeout(() => {
        this.$refs.mainRole.say();
        this.saying = true;
        setTimeout(() => {
          this.mainRoleNextArrow = true;
          this.nowSaying = "say1"; //说明say1 说完了
          this.saying = false;
        }, 3000);
      }, 200);
    },
    // 判断剧情走势
    nextSaying() {
      let timer3 = null;
      console.log(this.nowSaying);
      switch (this.nowSaying) {
        case "say1":
          if (this.saying) return;
          this.mainRoleWords = " ";
          this.$refs.mainRole.say();
          this.mainRoleNextArrow = false;
          this.mainRoleWords = "我刚刚不是在会议室吗？";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            setTimeout(() => {
              this.mainRoleNextArrow = true;
              this.nowSaying = "say2"; //说明say1 说完了
              this.saying = false;
            }, 2500);
          }, 200);
          break;

        case "say2":
          this.showViewer1 = true;
          this.showImgArrow = true;
          this.mainRoleWords = " ";
          this.$refs.mainRole.say();
          this.mainRoleNextArrow = false;
          this.mainRoleModal = false;
          this.nowSaying = "";
          break;

        case "imgArrowDone":
          this.showImgArrow = false;
          break;

        case "say3":
          if (this.saying) return;
          this.saying = true;
          this.mainRoleWords = " ";
          this.$refs.mainRole.say();
          this.mainRoleModal = true;
          this.mainRoleNextArrow = false;
          this.mainRoleWords = "这是......";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            setTimeout(() => {
              this.mainRoleNextArrow = true;
              this.nowSaying = "say4"; //说明say1 说完了
              this.saying = false;
            }, 1500);
          }, 200);
          break;

        case "say4":
          if (this.saying) return;
          this.nowSaying = "story2";
          this.mainRoleWords = "";
          this.$refs.mainRole.say();
          this.mainRoleModal = false;
          this.mainRoleNextArrow = false;
          this.canMove = true; // 可以走动了
          this.showGoRight = true;
          // 监听小兵移动的距离
          this.startListenMargin(-1);
          break;

        case "story2_say1":
          if (this.saying) return;
          this.saying = true;
          this.canMove = false;
          this.showGoRight = false;
          this.sister2Arrow = false;
          this.sister2Modal = true;
          this.sister2Moving = true;
          this.sister2words = "爸爸你看，这个小哥哥穿的上个展馆画上的人一样。";
          setTimeout(() => {
            this.$refs.sister2.say();
            this.saying = true;
            setTimeout(() => {
              this.saying = false;
              this.sister2NextArrow = true;
              this.nowSaying = "story2_say2";
            }, 3500);
          }, 200);
          break;

        case "story2_say2":
          if (this.saying) return;
          this.nowSaying = " ";
          this.sister2Modal = false;
          this.sister2Moving = false;
          this.mainRoleWords = "......";
          this.mainRoleModal = true;
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
          }, 100);
          setTimeout(() => {
            // this.showGoRight = true;  // 往右走箭头
            this.counter2List[0].show = true; //展厅2的  第一个展台提示点击
            this.saying = false;
            this.canMove = false;
            this.nowSaying = "counter2_1";
            this.mainRoleModal = false;
            this.mainRoleNextArrow = false;
          }, 2000);
          break;

        case "story2_say3":
          if (this.saying) return;
          this.saying = true;
          this.mainRoleWords = " ";
          this.mainRoleModal = true;
          this.mainRoleNextArrow = false;
          this.mainRoleWords = "这是...详细的会议记录？？？";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            setTimeout(() => {
              // this.mainRoleNextArrow = true;
              this.mainRoleModal = false;
              // this.counter2List[1].show = true; //展厅2的  第一个展台提示点击
              this.sister1Arrow = true;
              this.nowSaying = "sister1";
              this.saying = false;
            }, 3500);
          }, 200);
          break;

        case "story2_say4":
          if (this.saying) return;
          this.saying = true;
          this.canMove = false;
          this.showGoRight = false;
          this.sister1Arrow = false;
          this.sister1Modal = true;
          this.sister1Moving = true;
          this.sister1words = "这就是为我们的幸福生活做出努力的革命前辈们!";
          setTimeout(() => {
            this.$refs.sister1.say();
            this.saying = true;
            setTimeout(() => {
              this.saying = false;
              this.sister1NextArrow = true;
              this.nowSaying = "story2_say4_done";
            }, 3500);
          }, 200);
          break;

        case "story2_say4_done":
          if (this.saying) return;
          this.canMove = false;
          this.oldFatherArrow = true;
          this.sister1Modal = false;
          this.sister1Moving = false;
          this.nowSaying = "oldFather";
          this.canMove = false;
          break;

        case "story2_say5":
          if (this.saying) return;
          this.saying = true;
          this.canMove = false;
          this.oldFatherArrow = false;
          this.oldFatherModal = true;
          this.oldFatherMoving = true;
          this.oldFatherWords = "慧慧你看，这就是革命前辈们用过的东西。";
          setTimeout(() => {
            this.$refs.oldFather.say();
            setTimeout(() => {
              this.saying = false;
              this.oldFatherNextArrow = true;
              this.nowSaying = "story2_say5_done";
            }, 3000);
          }, 200);
          break;

        case "story2_say5_done":
          if (this.saying) return;
          this.saying = true;
          this.canMove = false;
          this.oldFatherMoving = false;
          this.oldFatherModal = false;
          this.oldFatherNextArrow = false;
          this.oldFatherWords = "";
          // 小妹妹说话
          this.sister2Modal = true;
          this.sister2Moving = true;
          this.sister2words =
            "这是帽子、手提箱、收音机,爸爸，第二个展柜的这是什么？";
          setTimeout(() => {
            this.$refs.sister2.say();
            setTimeout(() => {
              this.saying = false;
              this.sister2NextArrow = true;
              this.counter2List[1].show = true;
              this.nowSaying = "counter2_2";
            }, 4000);
          }, 200);
          break;

        case "story2_say6":
          if (this.saying) return;
          this.saying = true;
          this.sister2NextArrow = false;
          this.sister2Modal = false;
          this.sister2Moving = false;
          this.oldFatherWords = "这是烈士家书。";
          this.oldFatherModal = true;
          this.oldFatherMoving = true;
          setTimeout(() => {
            this.$refs.oldFather.say();
            setTimeout(() => {
              this.saying = false;
              this.oldFatherNextArrow = true;
              this.nowSaying = "story2_say6_done";
            }, 1500);
          }, 200);
          break;

        case "story2_say6_done":
          if (this.saying) return;
          this.saying = true;
          this.oldFatherMoving = false;
          this.oldFatherModal = false;
          this.oldFatherNextArrow = false;
          this.oldFatherWords = "";
          // 小妹妹说话
          this.sister2Modal = true;
          this.sister2Moving = true;
          this.sister2words = "什么是烈士家书呀？";
          setTimeout(() => {
            this.$refs.sister2.say();
            this.saying = true;
            setTimeout(() => {
              this.saying = false;
              this.sister2NextArrow = true;
              this.nowSaying = "story2_say7";
            }, 1500);
          }, 200);
          break;

        case "story2_say7":
          if (this.saying) return;
          this.saying = true;
          this.sister2Modal = false;
          this.sister2Moving = false;
          this.sister2NextArrow = false;
          this.oldFatherWords =
            "天下兴亡，匹夫有责。战争时期，许多战士原本只是普通人，为了挽救正处于危机时刻的国家，毅然加入解放队伍，挽救我们的国家。但穿上军装，他们是战士，脱下军装，他们也只是普通人。他们也有自己的家人，自己的挂念，所以在空闲时候，就会写下这些书信，寄给家人，表达自己的牵挂。";
          this.oldFatherModal = true;
          this.oldFatherMoving = true;
          setTimeout(() => {
            this.$refs.oldFather.say();
            this.saying = true;
            setTimeout(() => {
              this.saying = false;
              this.oldFatherNextArrow = true;
              this.oldFatherMoving = false;
              this.nowSaying = "story2_say7_done";
            }, 19500);
          }, 200);
          break;

        case "story2_say7_done":
          if (this.saying) return;
          this.saying = true;
          this.oldFatherModal = false;
          this.oldFatherNextArrow = false;
          this.oldFatherWords = "";
          // 小妹妹说话
          this.sister2Modal = true;
          this.sister2Moving = true;
          this.sister2words = "那他们的家人，都能收到他们的信吗？";
          setTimeout(() => {
            this.$refs.sister2.say();
            this.saying = true;
            setTimeout(() => {
              this.saying = false;
              this.sister2NextArrow = true;
              this.nowSaying = "story2_say8";
            }, 3000);
          }, 200);
          break;

        case "story2_say8":
          if (this.saying) return;
          this.saying = true;
          this.sister2Modal = false;
          this.sister2Moving = false;
          this.sister2NextArrow = false;
          this.oldFatherWords =
            "不是的，以前寄信比较困难，加上战争时期战士们一直在奔波，所以很多人的家书，其实并没有机会送到家人手上，他们只能贴身携带，等待有机会的时候再寄给家人。";
          this.oldFatherModal = true;
          this.oldFatherMoving = true;
          setTimeout(() => {
            this.$refs.oldFather.say();
            this.saying = true;
            setTimeout(() => {
              this.saying = false;
              this.oldFatherNextArrow = true;
              this.oldFatherMoving = false;
              this.nowSaying = "story2_say8_done";
            }, 11500);
          }, 200);
          break;

        case "story2_say8_done":
          if (this.saying) return;
          this.saying = true;
          this.oldFatherModal = false;
          this.oldFatherNextArrow = false;
          this.oldFatherWords = "";
          // 小妹妹说话
          this.sister2Modal = true;
          this.sister2Moving = true;
          this.sister2words =
            "我想妈妈的时候，可以给妈妈打电话，可是当时的军人哥哥，只能给妈妈寄信。";
          setTimeout(() => {
            this.$refs.sister2.say();
            this.saying = true;
            setTimeout(() => {
              this.saying = false;
              this.sister2NextArrow = true;
              this.nowSaying = "story2_say9";
            }, 5500);
          }, 200);
          break;

        case "story2_say9":
          if (this.saying) return;
          this.saying = true;
          this.sister2Modal = false;
          this.sister2Moving = false;
          this.sister2NextArrow = false;
          this.oldFatherWords =
            "所以要记得，我们今天的幸福生活来之不易，不能忘记这些前辈们的努力，是因为他们，才有了我们的今天。";
          this.oldFatherModal = true;
          this.oldFatherMoving = true;
          setTimeout(() => {
            this.$refs.oldFather.say();
            this.saying = true;
            setTimeout(() => {
              this.saying = false;
              this.oldFatherNextArrow = true;
              this.oldFatherMoving = false;
              this.nowSaying = "story2_say9_done";
            }, 7500);
          }, 200);
          break;

        case "story2_say9_done":
          if (this.saying) return;
          this.oldFatherModal = false;
          this.oldFatherNextArrow = false;
          this.oldFatherWords = "";
          this.nowSaying = " ";
          this.mainRoleModal = true;
          this.mainRoleWords = "❓❓❓❓❓";
          setTimeout(() => {
            this.$refs.mainRole.say();
          }, 200);
          setTimeout(() => {
            this.mainRoleModal = false;
            this.mainRoleNextArrow = false;
            this.canMove = true; // 可以走动了
            this.showGoRight = true;
            // 监听小兵移动的距离
            this.startListenMargin(-2);
          }, 2000);
          break;

        case "story3_say1":
          if (this.saying) return;
          this.saying = true;
          this.mainRoleWords = "";
          this.mainRoleModal = true;
          this.mainRoleNextArrow = false;
          this.mainRoleWords =
            "黑暗中高举革命的旗帜,以血与火的抗争，回答国民党的屠杀政策。";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            //  现代主角出现
            this.RecentRoleMoving = true;
            setTimeout(() => {
              let timer2 = setInterval(() => {
                this.recentRoleLeft -= 2;
                if (this.recentRoleLeft < 50) {
                  clearInterval(timer2);
                  setTimeout(() => {
                    this.RecentRoleMoving = false;
                  }, 1000);
                }
              }, 200);
              this.showRecentRole = true;
            }, 1000);
            setTimeout(() => {
              this.mainRoleNextArrow = true;
              this.nowSaying = "story3_say1_done"; //说明say1 说完了
              this.saying = false;
            }, 4500);
          }, 200);
          break;

        case "story3_say1_done":
          if (this.saying) return;
          this.saying = true;
          this.mainRoleWords = "";
          this.mainRoleModal = true;
          this.mainRoleNextArrow = false;
          this.mainRoleScare = true;
          this.mainRoleLeft = 18;
          this.mainRoleWords = "！（突然有人出现被吓了一跳）";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            setTimeout(() => {
              this.mainRoleNextArrow = true;
              this.nowSaying = "story3_say2"; //说明say1 说完了
              this.saying = false;
              this.mainRoleScare = false;
            }, 2500);
          }, 200);
          break;

        case "story3_say2":
          if (this.saying) return;
          this.saying = true;
          this.mainRoleWords = "";
          this.mainRoleModal = false;
          this.recentRoleWords = "";
          this.recentRoleModal = true;
          this.recentRoleNextArrow = false;
          this.recentRoleWords = "你好呀～我是这里的志愿者讲解员——小七。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.nowSaying = "story3_say2_done"; //说完了
              this.saying = false;
            }, 3000);
          }, 200);
          break;

        case "story3_say2_done":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleNextArrow = false;
          this.recentRoleWords =
            "先生第一次来的话，可以跟我一起，听我跟您讲解哦。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.nowSaying = "story3_say3"; //说完了
              this.saying = false;
            }, 4000);
          }, 200);
          break;

        case "story3_say3":
          if (this.saying) return;
          this.recentRoleNextArrow = false;
          this.recentRoleWords = "";
          this.recentRoleModal = false;
          this.saying = false;
          this.RecentRoleMoving = true;
          timer3 = setInterval(() => {
            this.recentRoleLeft -= 2;
            if (this.recentRoleLeft < 28) {
              // 主角
              this.mainRoleLeft = 18;
              this.nowSaying = "story3_say3_done";
              clearInterval(timer3);
              setTimeout(() => {
                this.RecentRoleMoving = false;
                this.nextSaying();
              }, 1000);
            }
          }, 200);
          break;

        case "story3_say3_done":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleModal = true;
          this.recentRoleNextArrow = false;
          this.recentRoleWords =
            "1927年，大革命失败。中国革命正出于最危急的时刻。1927年八月，中共中央分别致函河南，陕西，安徽，湖南等省委，同胞紧急会议精神，指导各省工作。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.nowSaying = "story3_say4"; //说完了
              this.saying = false;
            }, 11000);
          }, 200);
          break;

        case "story3_say4":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleModal = true;
          this.recentRoleNextArrow = false;
          this.recentRoleWords =
            "请大家一起看第一个展板，展板就是当时如何传播八七回忆精神的信息，以及民主革命时期的入党誓词，及中共临时党员证。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.station3_2 = true;
              this.recentRoleModal = false;
              this.nowSaying = " "; //说完了
              this.saying = false;
            }, 9000);
          }, 200);
          break;

        case "story3_say4_done":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleModal = false;
          this.recentRoleNextArrow = false;
          this.recentRoleWords = "";
          this.RecentRoleMoving = true;
          this.recentScaleX = 1;
          this.MainRoleMoving = true;

          this.recentRoleLeft = 44;
          this.mainRoleLeft = 32;
          timer3 = setInterval(() => {
            this.recentRoleLeft += 2;
            this.mainRoleLeft += 2;
            if (this.recentRoleLeft > 60) {
              clearInterval(timer3);
              setTimeout(() => {
                this.RecentRoleMoving = false;
                this.recentScaleX = -1;
                this.MainRoleMoving = false;
                this.saying = false;
                this.nowSaying = "story3_say5";
                this.nextSaying();
              }, 1000);
            }
          }, 200);
          break;

        case "story3_say5":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleModal = true;
          this.recentRoleNextArrow = false;
          this.recentRoleWords =
            "这里展示的是1927年八七会议后，在土地革命时期，国内爆发了三大起义，即——南昌起义，秋收起义，及广州起义。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleModal = false;
              this.station3_3 = true;
              this.recentRoleNextArrow = true;
              this.saying = false;
              this.nowSaying = "move3";
            }, 9500);
          }, 200);
          break;

        case "story3_say5_done":
          if (this.saying) return;
          this.canMove = false;
          this.saying = true;
          this.recentRoleModal = false;
          this.recentRoleNextArrow = false;
          this.recentRoleWords = "";
          this.RecentRoleMoving = true;
          this.scaleX = -1;
          this.MainRoleMoving = true;
          timer3 = setInterval(() => {
            this.leftMargin += this.speed * 30;
            if (this.mainRoleLeft < 91) {
              this.mainRoleLeft += 3;
            }
            if (this.recentRoleLeft > 76) {
              this.recentRoleLeft -= 3;
              console.log(this.recentRoleLeft);
            }
            if (this.leftMargin - this.speed * 30 > -1 * this.phoneWidth) {
              this.leftMargin = -1 * this.phoneWidth;
              this.RecentRoleMoving = false;
              this.MainRoleMoving = false;
              this.nowSaying = "story3_say6";
              this.saying = false;
              setTimeout(() => {
                this.nextSaying();
              }, 1000);
              clearInterval(timer3);
            }
          }, 200);
          break;

        case "story3_say6":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleWords = "";
          this.recentRoleModal = true;
          this.recentRoleNextArrow = false;
          this.recentRoleWords =
            "无数的革命先辈前赴后继，才为我们换来了今天的幸福生活，因此也希望大家能铭记历史，不忘先烈。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.nowSaying = "story3_say6_done"; //说完了
              this.saying = false;
            }, 8000);
          }, 200);
          break;

        case "story3_say6_done":
          if (this.saying) return;
          this.recentRoleModal = false;
          this.saying = true;
          this.sister1Modal = true;
          this.sister1Moving = true;
          this.sister1words = "是的，历史应该被铭记。";
          setTimeout(() => {
            this.$refs.sister1.say();
            this.saying = true;
            setTimeout(() => {
              this.sister1Modal = false;
            }, 3000);
          }, 200);

          setTimeout(() => {
            this.sister1Moving = false;
            this.sister2Modal = true;
            this.sister2Moving = true;
            this.sister2words =
              "爸爸，我们现在的过很幸福，那些牺牲的革命先辈却不能亲眼看到，他们会不会遗憾呀？";
            setTimeout(() => {
              this.$refs.sister2.say();
              this.saying = true;
              setTimeout(() => {
                this.sister2NextArrow = true;
                this.nowSaying = "story3_say7";
                this.saying = false;
              }, 6500);
            }, 200);
          }, 2500);
          break;

        case "story3_say7":
          if (this.saying) return;
          this.saying = true;
          this.canMove = false;
          this.oldFatherModal = true;
          this.oldFatherMoving = true;
          this.sister2Modal = false;
          this.oldFatherWords = "我想，他们一定会很欣慰的。";
          setTimeout(() => {
            this.$refs.oldFather.say();
            setTimeout(() => {
              this.saying = false;
              this.oldFatherNextArrow = true;
              this.nowSaying = "story3_say7_done";
            }, 2500);
          }, 200);
          break;

        case "story3_say7_done":
          if (this.saying) return;
          this.saying = true;
          this.oldFatherModal = false;
          this.humanLeave = true;
          this.MainRoleMoving = true;
          timer3 = setInterval(() => {
            if (this.mainRoleLeft > 50) {
              this.mainRoleLeft -= 3;
            } else {
              clearInterval(timer3);
              this.scaleX = 1;
              this.nowSaying = "story3_say8";
              this.nextSaying();
              this.saying = false;
              setTimeout(() => {
                this.MainRoleMoving = false;
              }, 500);
              setTimeout(() => {
                this.nextSaying();
              }, 1500);
            }
          }, 200);
          break;

        case "story3_say8":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleWords = "";
          this.recentRoleModal = true;
          this.recentRoleNextArrow = false;
          this.recentRoleWords =
            "你呢？...会觉得遗憾吗？你和我长得很像,但是穿着格外的不同。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.nowSaying = "story3_say8_done"; //说完了
              this.saying = false;
            }, 4500);
          }, 200);
          break;

        case "story3_say8_done":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleNextArrow = false;
          this.recentRoleModal = false;
          this.mainRoleModal = true;
          this.mainRoleWords = "…… 今夕何年？";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            setTimeout(() => {
              this.mainRoleNextArrow = true;
              this.nowSaying = "story3_say9"; //说完了
              this.saying = false;
            }, 2000);
          }, 200);
          break;

        case "story3_say9":
          if (this.saying) return;
          this.saying = true;
          this.mainRoleNextArrow = false;
          this.mainRoleModal = false;
          this.recentRoleWords = "";
          this.recentRoleModal = true;
          this.recentRoleWords = "2021年。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.nowSaying = "story3_say9_done"; //说完了
              this.saying = false;
            }, 1200);
          }, 200);
          break;

        case "story3_say9_done":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleNextArrow = false;
          this.recentRoleModal = false;
          this.mainRoleModal = true;
          this.mainRoleWords = "你们生活的好吗？还会有战争吗？可以餐餐吃饱吗？";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            setTimeout(() => {
              this.mainRoleNextArrow = true;
              this.nowSaying = "story3_say10"; //说完了
              this.saying = false;
            }, 3800);
          }, 200);
          break;

        case "story3_say10":
          if (this.saying) return;
          this.saying = true;
          this.mainRoleNextArrow = false;
          this.mainRoleModal = false;
          this.recentRoleWords = "";
          this.recentRoleModal = true;
          this.recentRoleWords = "山河无恙，国泰民安，衣食无忧。";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.nowSaying = "story3_say10_done"; //说完了
              this.saying = false;
            }, 2200);
          }, 200);
          break;

        case "story3_say10_done":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleNextArrow = false;
          this.recentRoleModal = false;
          this.mainRoleModal = true;
          this.mainRoleWords = "那我，没有遗憾了。";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            setTimeout(() => {
              this.mainRoleNextArrow = true;
              this.nowSaying = "story3_say11"; //说完了
              this.saying = false;
            }, 1800);
          }, 200);
          break;

        case "story3_say11":
          if (this.saying) return;
          this.saying = true;
          this.mainRoleNextArrow = false;
          this.mainRoleModal = false;
          this.recentRoleWords = "";
          this.recentRoleModal = true;
          this.recentRoleWords = "我可以和你一起拍张照吗？";
          setTimeout(() => {
            this.$refs.recentRole.say();
            this.saying = true;
            setTimeout(() => {
              this.recentRoleNextArrow = true;
              this.nowSaying = "story3_say11_done"; //说完了
              this.saying = false;
            }, 2600);
          }, 200);
          break;

        case "story3_say11_done":
          if (this.saying) return;
          this.saying = true;
          this.recentRoleNextArrow = false;
          this.recentRoleModal = false;
          this.mainRoleModal = true;
          this.mainRoleWords = "拍照？用照相机吗？可以呀";
          setTimeout(() => {
            this.$refs.mainRole.say();
            this.saying = true;
            setTimeout(() => {
              this.mainRoleNextArrow = true;
              this.nowSaying = "story3_say12"; //说完了
              this.saying = false;
            }, 2000);
          }, 200);
          break;

        case "story3_say12":
          if (this.saying) return;
          this.nowSaying = "camera";
          this.mainRoleNextArrow = false;
          this.mainRoleModal = false;
          setTimeout(() => {
            this.showCamera = true;
            this.showMetting = true;
          }, 200);
          setTimeout(() => {
            this.showCamera = false;
            this.showPhotos = true;
          }, 1000);
          break;

        case "camera":
          if (this.saying) return;
          setTimeout(() => {
            this.saying = true;
            this.recentRoleNextArrow = false;
            this.recentRoleModal = false;
            this.mainRoleModal = true;
            this.mainRoleWords = "那么，再见拉。";
            setTimeout(() => {
              this.$refs.mainRole.say();
              this.saying = true;
              setTimeout(() => {
                this.mainRoleNextArrow = true;
                this.nowSaying = "beingBack"; //说完了
                this.saying = false;
              }, 1500);
            }, 200);
          }, 1000);
          break;

        case "beingBack":
          this.nowSaying = "";
          this.showDream = true;
          this.mainRoleModal = false;
          this.$refs.mainRole.showWords = "";
          this.showRecentRole = false;
          setTimeout(() => {
            this.hideMainRole = true;
          }, 2000);
          setTimeout(() => {
            this.showDream = false;
          }, 3000);
          setTimeout(() => {
            this.MainRoleMoving = true;
            this.hideMainRoleDone = true;
            setTimeout(() => {
              this.MainRoleMoving = false;
            }, 1500);
            setTimeout(() => {
              this.scaleX = -1;
            }, 2000);
            setTimeout(() => {
              this.scaleX = 1;
            }, 3000);
            setTimeout(() => {
              this.mainRoleModal = true;
              this.mainRoleWords = "天亮了！太阳升起来了！";
              this.saying = true;
              setTimeout(() => {
                this.$refs.mainRole.say();
                setTimeout(() => {
                  this.mainRoleNextArrow = true;
                  this.saying = false;
                  this.nowSaying = "sunUp";
                }, 1500);
              }, 500);
            }, 4000);
          }, 5000);
          break;

        case "sunUp":
          this.mainRoleNextArrow = false;
          this.mainRoleModal = false;
          this.showFinal = true;
          break;

        default:
          break;
      }
    },
    // 点击主角
    clickMainRole() {
      if (this.saying) return;
      if (this.nowSaying == "story3") {
        this.mainRoleExclamation = false;
        this.nowSaying = "story3_say1";
      }
    },
    // 点击小女孩
    clickSister2() {
      if (this.saying) return;
      if (this.showGoRight) return;
      if (this.nowSaying == "story2") {
        this.nowSaying = "story2_say1";
      }
    },
    // 点击小姐姐
    clickSister1() {
      console.log("点击小姐姐1");
      if (this.saying) return;
      if (this.nowSaying == "sister1") {
        this.nowSaying = "story2_say4";
        this.nextSaying();
      }
    },
    // 点击老父亲
    clickOldfather() {
      console.log("点击老父亲");
      if (this.saying) return;
      if (this.nowSaying == "oldFather") {
        this.nowSaying = "story2_say5";
      }
    },
    // 完结点击
    finalClick() {
      this.finalImg++;
      if (this.finalImg > 3) {
        this.ending = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.firstBlock {
  height: 100%;
  width: 100%;
}
// 音乐按钮
.audioContral {
  position: absolute;
  height: 40px;
  width: 40px;
  padding-top: 10px;
  top: 0;
  right: 0;
  z-index: 9999;
  img {
    height: 25px;
    width: 25px;
    margin: 0 auto;
  }
}
.audioContralRotate {
  img {
    animation: rotate 2s linear 0s infinite forwards;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.outLineBox {
  width: 100%;
  height: 100%;
  position: relative;
  color: black;
  background-size: 100% 100%;
  overflow: hidden;
  user-select: none;
  transform-style: preserve-3d;
  perspective-origin: center center;
  perspective: 800px;
  .contentBox {
    height: 100%;
    width: 100%;
    position: relative;
  }
  ul {
    position: relative;
    height: 100%;
    width: 300%;
    user-select: none;
    transition: 0.8s ease-out 0s all;
    li {
      position: relative;
      display: inline-block;
      text-align: center;
      height: 100%;
      box-sizing: border-box;
      user-select: none;
      z-index: 1;
      // background-color: #f8d08e;
      .bgImg {
        height: 100%;
        width: 100%;
      }
      .human {
        position: absolute;
        left: 0;
        bottom: 70px;
        width: 100%;
        height: 180px;
        opacity: 1;
        z-index: 20;
        .person {
          display: inline-block;
        }
        transition: 5s ease-in 0s all;
      }
      .humanLeave {
        opacity: 0;
        left: -100%;
      }
    }
  }
  .imgCover1 {
    position: absolute;
    height: 51%;
    width: 23%;
    top: 9%;
    left: 3%;
    opacity: 0;
    z-index: 10;
    // overflow: hidden;
    img {
      position: absolute;
    }
    img:nth-child(1) {
      left: 280%;
      width: 100%;
      height: 92% !important;
    }
    img:nth-child(2) {
      left: 114%;
      top: 2%;
      height: 66%;
      width: 156%;
    }
    img:nth-child(3) {
      left: 29%;
      width: 88%;
    }
  }
  .imgCover2 {
    position: absolute;
    height: 51%;
    width: 23%;
    top: 3%;
    left: 3%;
    opacity: 0;
    z-index: 10;
    // overflow: hidden;
    img {
      position: absolute;
    }
    img:nth-child(1) {
      left: 12%;
      height: 100% !important;
    }
    img:nth-child(2) {
      left: 102%;
      top: -1%;
      height: 71%;
    }
    img:nth-child(3) {
      left: 203%;
      width: 56%;
      height: 72%;
      top: -2%;
    }
    img:nth-child(4) {
      left: 263%;
      top: -5px;
      height: 72%;
      width: 150%;
    }
  }
  .imgCover3 {
    position: absolute;
    height: 51%;
    width: 23%;
    top: 3%;
    left: 3%;
    opacity: 0;
    z-index: 10;
    // overflow: hidden;
    img {
      position: absolute;
    }
    img:nth-child(1) {
      left: 21%;
      height: 105% !important;
      width: 67%;
      top: -1%;
    }
    img:nth-child(2) {
      left: 94%;
      top: 20%;
      height: 64%;
    }
    img:nth-child(3) {
      left: 207%;
      width: 123%;
      top: 24%;
    }
    img:nth-child(4) {
      left: 336%;
      top: 39px;
      height: 80%;
    }
  }
  // 1920 主角
  .mainRole {
    position: absolute;
    left: 10%;
    transform: translateX(-50%);
    bottom: 24px;
    z-index: 5;
    transition: 0.8s ease-out 0s all;
  }
  .hideMainRole {
    left: 27% !important;
    top: 33% !important;
    zoom: 0.8 !important;
    opacity: 0 !important;
  }
  .hideMainRoleDone {
    left: 37% !important;
    top: 33% !important;
    zoom: 0.8 !important;
    opacity: 1 !important;
  }
  // 现代 主角
  .recentRole {
    height: 150px;
    width: 150px;
    position: absolute;
    left: 70%;
    transform: translateX(-50%);
    bottom: 14px;
    z-index: 5;
    transition: 0.8s ease-out 0s all;
  }
  // loading
  .loadingDiv {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 50;
  }
  //横屏提示 z-index 最高
  .rotateTipsDiv {
    z-index: 99999;
  }
  // 操作按钮
  .btnBox {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 100%;
    height: 50px;
    z-index: 20;
    span {
      flex: 1;
      width: 60px;
      // border: 1px solid black;
      text-align: center;
      line-height: 50px;
      user-select: none;
    }
  }
}
@media screen and (orientation: landscape) {
  .outLineBox {
    ul {
      height: 100%;
    }
    .btnBox {
      bottom: 0px;
      z-index: 20;
    }
  }
}

// 围观群众
.people {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  .people {
    position: absolute;
    bottom: 0px;
    height: 120px;
    width: 100px;
    color: #fff;
    text-align: center;
    z-index: 100;
    zoom: 1.1;
    // background-color: rgba(0, 0, 0, 0.856);
    transform-origin: 50% 100%;
    img {
      height: 100%;
    }
  }
  .people1 {
    z-index: 100;
    height: 150px;
    left: -6%;
  }
  .people2 {
    height: 130px;
    left: -10%;
  }
  .people3 {
    left: 2%;
    height: 120px;
  }
  .people4 {
    height: 150px;
    left: 8%;
    animation: peopleShaking 0.5s ease-in 0.4s infinite alternate;
  }
  .people5 {
    height: 120px;
    left: 17%;
  }
  .people6 {
    height: 130px;
    left: 20%;
    animation: peopleShaking 0.5s ease-in 0.9s infinite alternate;
  }
  .people7 {
    height: 132px;
    left: 26%;
  }
  .people8 {
    height: 115px;
    left: 35%;
    animation: peopleShaking 0.5s ease-in 1.3s infinite alternate;
  }
  .people9 {
    height: 118px;
    left: 39%;
  }
  .people10 {
    height: 133px;
    left: 44%;
  }
  .people11 {
    height: 150px;
    left: 49%;
    animation: peopleShaking 0.5s ease-in 1.2s infinite alternate;
  }
  .people12 {
    height: 122px;
    left: 56%;
  }
  .people13 {
    height: 139px;
    left: 59%;
  }
  .people14 {
    height: 134px;
    left: 64%;
    animation: peopleShaking 0.5s ease-in infinite alternate;
  }
  .people15 {
    height: 123px;
    left: 68%;
  }
  .people16 {
    height: 154px;
    left: 29%;
  }
}
@keyframes peopleShaking {
  from {
    transform: rotate(-4deg);
  }
  to {
    transform: rotate(5deg);
  }
}
// 图片箭头
.imgArrow {
  position: absolute;
  height: 40px;
  width: 40px;
  text-align: center;
  top: 10%;
  left: 18%;
  z-index: 120;
  zoom: 1.5;
  animation: imgArrowShaking 0.5s ease-in infinite alternate;
}
.imgArrow2 {
  left: 44%;
}
.imgArrow3 {
  left: 75%;
}
@keyframes imgArrowShaking {
  from {
    top: 8%;
  }
  to {
    top: 12%;
  }
}
.goRightArrow {
  position: absolute;
  height: 40px;
  width: 40px;
  text-align: center;
  top: 42%;
  right: 5%;
  z-index: 120;
  zoom: 2.5;
  animation: imgArrowShaking2 0.5s ease-in infinite alternate;
}
.goRightNext {
  position: absolute;
  height: 40px;
  width: 40px;
  text-align: center;
  top: 82%;
  right: 5%;
  z-index: 120;
  zoom: 1.5;
  animation: imgArrowShaking2 0.5s ease-in infinite alternate;
}
@keyframes imgArrowShaking2 {
  from {
    right: 4%;
  }
  to {
    right: 6%;
  }
}
.counter2 {
  height: 55px;
  position: absolute;
  left: 29%;
  width: 69%;
  top: 33%;
  z-index: 30;
  // background-color: #fff;
  ul {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    li {
      flex: 1;
      position: relative;
      .imgViewer {
        opacity: 0;
        height: 100%;
        width: 100%;
        img {
          margin-top: 23%;
          height: 50%;
          width: 100%;
        }
      }
      .imgArrow {
        position: absolute;
        height: 40px;
        width: 40px;
        text-align: center;
        top: 10%;
        left: 36%;
        z-index: 120;
        zoom: 1;
        animation: imgArrowShaking 0.5s ease-in infinite alternate;
      }
    }
  }
}

// 拍照特效
.camera {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: #fff;
  opacity: 0;
  animation: camera1 0.5s ease-out 2 alternate forwards;
}
.photo {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 210;
  background-color: #000;
  .photoImg {
    height: 100%;
    left: 50%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
.dream {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 210;
  background-color: #000;
}

@keyframes camera1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* portrait */
@media screen and (orientation: portrait) {
  /* 正常情况下 */
  /* portrait-specific styles */
}

/* landscape */
/* 宽屏情况下 */
@media screen and (orientation: landscape) {
  /* landscape-specific styles */
}

// 过渡
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

//结尾动画
.motoDiv {
  position: absolute;
  height: 140px;
  width: 135px;
  transition: all 0.5s ease-in;
  left: 5%;
  top: 55%;
  zoom: 0.8;
  .moto {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 5;
  }
  .circle {
    position: absolute;
    height: 40px;
    width: 40px;
    z-index: 1;
    top: 78%;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .circle1 {
    bottom: -10px;
    right: 7px;
    animation: rolling 2s linear infinite forwards;
  }
  .circle2 {
    bottom: -10px;
    left: 8px;
    animation: rolling 2s linear 1s infinite forwards;
  }
  .gas {
    height: 18px;
    width: 20px;
    position: absolute;
    z-index: 10;
    bottom: 4px;
    left: -16px;
    animation: gasMove 0.8s linear 0s infinite forwards;
  }
}
.ending {
  left: 80%;
}
.rush {
  left: 200% !important;
  opacity: 0;
}
@keyframes gasMove {
  0% {
    left: -16px;
    bottom: 4px;
  }
  100% {
    left: -26px;
    bottom: 10px;
    opacity: 0;
  }
}
@keyframes rolling {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(720deg);
  }
}
.pictureShow {
  position: absolute;
  z-index: 10000;
  top: 2%;
  width: 44%;
  left: 50%;
  transform: translateX(-50%);
  h3 {
    display: block;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
  }
  img {
    position: absolute;
    left: 0;
    width: 100%;
    background: black;
    border-radius: 10px;
  }
}
.designBanner {
  color: #fff;
  font-size: 17px;
  position: absolute;
  z-index: 10000;
  top: 10%;
  width: 170px;
  right: 1%;
  p {
    margin-bottom: 5px;
  }
}
</style>
