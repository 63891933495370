import { render, staticRenderFns } from "./mainRole.vue?vue&type=template&id=782dc45f&scoped=true&"
import script from "./mainRole.vue?vue&type=script&lang=js&"
export * from "./mainRole.vue?vue&type=script&lang=js&"
import style0 from "./mainRole.vue?vue&type=style&index=0&id=782dc45f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "782dc45f",
  null
  
)

export default component.exports