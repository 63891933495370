<template>
  <div class="rotateTips" >
    <a class="arrow" href="#">Refresh</a>
    <div class="tips">
      <p style="margin-bottom:20px;">请横屏观看哦</p>
      <p>安卓系统请打开自动旋转；<br />微信【设置】->【通用】-><br />【开启横屏模式】;</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped >
.rotateTips {
  background: #3e6fa3;
  font-size: 62.5%;
}
.tips {
  font-size: 20px;
  margin: 20% auto;
  text-align: center;
  color: #fff;
  /* animation: fontRotate 3s infinite; */
}
@keyframes fontRotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  80% {
    color: rgb(194, 191, 15);
    -webkit-transform: rotate(-70deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(80deg);
  }
  80% {
    -webkit-transform: rotate(-160deg);
  }
  100% {
    -webkit-transform: rotate(80deg);
  }
}
.arrow {
  font-size: 1em;
  color: #fff;
  text-decoration: none;
  display: block;
  position: absolute;
  width: 2em;
  height: 2em;
  text-indent: -9999px;
  border: 0.5em solid #fff;
  border-left-color: transparent;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin: -1em 0 0 -1em;
  -webkit-transform: rotate(45deg);
  transform: rotate(80deg);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  animation: rotate 2s infinite;
}
.arrow:before {
  width: 0;
  height: 0;
  content: "";
  display: block;
  position: absolute;
  top: -0.35em;
  left: -0.35em;
  border: 0.5em solid #fff;
  border-right-color: transparent;
  border-top-color: transparent;
}
.arrow:after {
  width: 5em;
  height: 5em;
  content: "";
  border: 0.5em solid #fff;
  display: block;
  position: absolute;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>