<template>
  <div class="bgs" @click="goMain">
    <div class="filter"></div>
    <div class="content">
      <h3>本故事纯属虚构 如有雷同 实属巧合</h3>
      <section>{{ showWords }}</section>
      <span class="arrow" v-if="showArrow">👉</span>
      <!-- 跳过层 -->
      <span class="jumpNext" @click="goMainNow">跳过 >></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "BackgroundStory",
  data() {
    return {
      showWords: "",
      words:
        "1927年，蒋介石、汪精卫先后背叛革命，第一次国内革命战争失败，中国革命正处于一个十分紧急的关头，中国共产党处于思想混乱与组织凌乱的时候，为了挽救中国革命，中共中央政治局决定于1927年8月7日在汉口召开紧急会议，商讨革命斗争的方向，会议结束后，班长小八被安排留在会议室，将会议室恢复原样，随后归队，故事由此开始。",
      timer: null,
      showArrow: false,
    };
  },
  mounted() {
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
      this.showWords = "";
    }
    setTimeout(() => {
      this.judgeStart();
    }, 2000);
  },
  destroyed() {
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
      this.showWords = "";
    }
  },
  methods: {
    judgeStart() {
      if (window.orientation != 0) {
        this.showing();
      } else {
        console.log("请横屏观看哦！");
        setTimeout(() => {
          this.judgeStart();
        }, 2000);
      }
    },
    showing() {
      this.showWords = "";
      let arr = [...this.words];
      let index = 0;
      this.timer = setInterval(() => {
        this.showWords += arr[index];
        index++;
        if (index >= arr.length) {
          clearInterval(this.timer);
          setTimeout(() => {
            this.showArrow = true;
          }, 1000);
        }
      }, 50);
    },
    goMain() {
      // this.$emit("bgsDone", true);
      if (this.showArrow) {
        this.$emit("bgsDone", true);
      }
    },
    goMainNow() {
      this.$emit("bgsDone", true);
    },
  },
};
</script>
<style lang="less" scoped >
.bgs {
  position: relative;
  background-color: #0e1217;
  box-sizing: border-box;
  .filter {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url(https://suezp.cn/server//meetingRoom.png) no-repeat;
    background-size: cover;
    -webkit-filter: brightness(0.3);
    filter: brightness(0.3);
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    padding: 10px;
    z-index: 10;
    box-sizing: border-box;
    h3 {
      margin-bottom: 10px;
      margin-top: 30px;
      text-align: center;
    }
    section {
      margin-top: 20px;
      text-align: left;
      padding: 0 30px;
      text-indent: 2rem;
      line-height: 35px;
    }
    .arrow {
      position: absolute;
      height: 50px;
      width: 50px;
      right: 10px;
      bottom: 10px;
      z-index: 10;
      line-height: 50px;
      text-align: center;
      animation: shaking 1s ease-in infinite;
    }
    @keyframes shaking {
      0% {
        right: 5px;
      }
      50% {
        right: 15px;
      }
      100% {
        right: 5px;
      }
    }
    .jumpNext {
      position: absolute;
      height: 26px;
      width: 70px;
      color: #fff;
      top: 13px;
      right: 100px;
    }
    @media screen and (max-width: 570px) {
      .jumpNext {
        right: 50px
      }
    }
  }
}
</style>