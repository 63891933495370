<template>
  <div :class="['meetingRole', { wakeUp: wakeUp }]">
    <img
      :src="wakeUp?'https://suezp.cn/server//meetingRoom.png':'https://suezp.cn/server//meetingRoom.png'"
      class="bgImg"
      alt="mettingRoom"
    />
    <!-- 初始旁白 -->
    <transition name="fade">
      <div class="asideBox" v-show="!showStory">{{ showWords }}</div>
    </transition>
    <!-- 椅子上的纸条层 -->
    <div class="things" v-if="showThings">
      <span class="paper" v-show="paperList.paper1">-------</span>
      <span class="paper" v-show="paperList.paper2">------</span>
      <span class="paper" v-show="paperList.paper3">-----</span>
      <span class="paper" v-show="paperList.paper4">--------</span>
      <transition name="fade">
        <span class="noisy" v-if="paperList.noisy">
          <img src="https://suezp.cn/server//noisy.png" alt="嘈杂声" />
        </span>
      </transition>
    </div>
    <!-- 后面的旁白 -->
    <transition name="fade">
      <div class="topBox" v-show="showAside">
        {{ asideWords }}
      </div>
    </transition>
    <!-- 壁炉 -->
    <transition name="fade">
      <div class="sleepRole" v-show="showStory" @click="clickRole">
        <div class="coverBox" style="z-index: 200;"></div>
        <span class="arrow clickTips" v-if="showClickTips && !hiding">👉</span>
        <!-- <div class="sleepSign">
          <ul>
            <li>z</li>
            <li>z</li>
            <li>z</li>
            <li>z</li>
            <li>z</li>
          </ul>
        </div> -->
      </div>
    </transition>
    <!-- 主角 -->
    <transition name="fade">
      <div :class="['mainRole', { hiding: hiding }, { goaway: goaway }]">
        <main-role
          ref="mainRole"
          :srcList="mainRoleSrcList"
          :scaleX="scaleX"
          :modal="mainRoleModal"
          :cleanMoving="MainRoleMoving"
          :moving="normalMoving"
          :words="mainRoleWords"
          :isClean="!normalMoving"
          :hide="hiding"
        >
        </main-role>
      </div>
    </transition>
    <!-- 躲起来的主角的旁白 -->
    <transition name="fade">
      <div class="asideModal" v-if="showAsideModal">{{ showWords }}</div>
    </transition>
    <!-- 眨眼动效层 以及提示箭头点击 -->
    <transition name="fade">
      <div
        :class="['eyeCover', { eyesMoving: eyesMoving }]"
        v-if="eyeAnimation"
        @click="goMain"
      >
        <div class="top"></div>
        <div class="bottom"></div>
        <span class="arrow" v-if="showArrow">👉</span>
      </div>
    </transition>
    <!-- 告示牌子 -->
    <transition name="fade">
      <div class="coverBox" v-if="wakeUp || changePage">
        <div class="banner">
          <h4 style="height: 26px; margin-top: 11px">八七会议旧址</h4>
          <p style="font-size: 14px">文明观赏</p>
        </div>
      </div>
    </transition>
    <!-- 转场点击层 -->
    <transition name="fade">
      <div class="changePage" @click="goNextMain" v-if="changePage">
        <span class="arrow" v-if="showArrow">👉</span>
      </div>
    </transition>
  </div>
</template>
<script>
import MainRole from "./components/mainRole";
export default {
  name: "MeetingRoom",
  components: {
    MainRole,
  },
  data() {
    return {
      asideWords: "1927年8月7日 俄租界三教街41号 小八正在还原会议室",
      showWords: "",
      Zlist: ["z", "z"],
      timer: null,
      showStory: false,
      showArrow: false,
      showAside: false,
      eyeAnimation: false,
      eyesMoving: false,
      showClickTips: false,
      wakeUp: false,
      showAsideModal: false,
      changePage: false,
      showThings: true, //纸条层
      goaway: false,
      old:true,
      paperList: {
        paper1: true,
        paper2: true,
        paper3: true,
        paper4: true,
        noisy: false,
      },
      // 1927主角
      mainRoleSrcList: [
        "mainRole/head.png",
        "mainRole/body.png",
        "mainRole/arm1.png",
        "mainRole/arm2.png",
        "mainRole/leg2.png",
        "mainRole/leg1.png",
      ],
      hiding: false,
      MainRoleMoving: false, //打扫动画
      normalMoving: false, //走动动画
      mainRoleModal: false,
      mainRoleWords: "...",
      scaleX: -1,
    };
  },
  mounted() {
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
      this.showWords = "";
    }
    setTimeout(() => {
      this.showing();
      setTimeout(() => {
        
      }, 2000);
    }, 2000);
  },
  destroyed() {
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
      this.showWords = "";
    }
  },
  methods: {
    // 点击壁炉
    clickRole() {
      if (!this.showClickTips) {
        return;
      }
      this.normalMoving = true;
      setTimeout(() => {
        this.normalMoving = false;
      }, 4000);
      this.scaleX = -1;
      this.hiding = true;
      this.showAside = false;
      setTimeout(() => {
        this.asideWords =
          "不知道躲了多久...时间仿佛也放慢了速度,不知是不是由于太过劳累壁橱里的小八，眼皮慢慢变得沉重起来.....";
        this.showAside = true;
        setTimeout(() => {
          this.eyeAnimation = true;
          this.eyesMoving = true;
          this.showAside = false;
          setTimeout(() => {
            this.showArrow = true;
          }, 8000);
        }, 5000);
      }, 2000);
    },
    // 点醒主角
    goMain() {
      if (!this.showArrow) {
        return;
      }
      if(this.wakeUp) {
        return
      }
      this.scaleX = 1;
      this.eyeAnimation = false;
      this.eyesMoving = false;
      this.showClickTips = false;

      // 以为没人准备出来
      this.wakeUp = true; // 控制告示牌
      setTimeout(() => {
        this.normalMoving = true;
        this.sayHide();
      }, 1000);
      setTimeout(() => {
        this.hiding = false;
        this.showArrow = true;
        setTimeout(() => {
          this.normalMoving = false;
        }, 3000);
      }, 8000);

      // 发现有人
      setTimeout(() => {
        this.mainRoleWords = "😳 怎么有这么多人看我啊？溜了，溜了。";
        setTimeout(() => {
          this.mainRoleModal = true;
          this.$refs.mainRole.say();
        }, 1000);
      }, 10000);
      // 围观群众
      setTimeout(() => {
        this.mainRoleModal = false;
        this.$emit("showPeoples", true);
      }, 15000);
      // 点击层
      setTimeout(() => {
        this.goaway = true;
        this.normalMoving = true;
        this.changePage = true;
      }, 20000);
    },
    sayHide() {
      this.showAsideModal = true;
      let str = "...好像没人了，我要快点和长官们汇合！！";
      this.showWords = "";
      let arr = [...str];
      let index = 0;
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = setInterval(() => {
        this.showWords += arr[index];
        index++;
        if (index >= arr.length) {
          setTimeout(() => {
            this.showAsideModal = false;
          }, 1000);
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 150);
    },
    ///开场
    showing() {
      this.showWords = "";
      let arr = [...this.asideWords];
      let index = 0;
      this.timer = setInterval(() => {
        this.showWords += arr[index];
        index++;
        if (index >= arr.length) {
          clearInterval(this.timer);
          this.timer = null;
          this.MainRoleMoving = true;
          setTimeout(() => {
            this.goingStory();
          }, 2000);
        }
      }, 100);
    },
    goingStory() {
      let role = document.querySelector(".mainRole");
      role.classList.add("cleanAnimation");
      // 收拾纸条
      setTimeout(() => {
        this.paperList.paper2 = false;
      }, 100);
      setTimeout(() => {
        this.paperList.paper3 = false;
      }, 1500);
      setTimeout(() => {
        this.paperList.paper1 = false;
      }, 3500);
      setTimeout(() => {
        this.paperList.paper4 = false;
        this.paperList.noisy = true;
      }, 6500);
      setTimeout(() => {
        this.paperList.noisy = false;
        this.showThings = false;
      }, 9000);

      setTimeout(() => {
        this.showStory = true;
        this.MainRoleMoving = false;
        this.asideWords = "好像有谁过来了，快躲起来。点击壁炉让小八躲进去！！";
        setTimeout(() => {
          this.showAside = true;
        }, 1000);
        this.scaleX = 1;
        setTimeout(() => {
          this.showClickTips = true;
        }, 2000);
        role.classList.remove("cleanAnimation");
      }, 8000);
    },
    goNextMain() {
      this.$emit("showPeoples", false);
      this.$emit("clickRole", true);
    },
  },
};
</script>
<style lang="less" scoped>
.meetingRole {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  text-align: center;
  transition: 2s ease-out 15s all;
  .bgImg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  // zzz睡着动画
  .sleepRole {
    position: relative;
    height: 150px;
    width: 90px;
    // border: 1px solid black;
    left: 24%;
    top: 30%;
    .clickTips {
      position: absolute;
      // height: 50px;
      width: 50px;
      z-index: 10;
      line-height: 50px;
      text-align: center;
      transform: rotate(90deg);
      left: 23%;
      top: -30%;
      animation: clickShaking 1s ease-in infinite;
    }
    @keyframes clickShaking {
      0% {
        top: -32%;
      }
      50% {
        top: -29%;
      }
      100% {
        top: -32%;
      }
    }
    .sleepSign {
      overflow: hidden;
      position: absolute;
      color: #000;
      font-size: 30px;
      left: 35px;
      top: -130px;
      ul {
        height: 120px;
        width: 60px;
        // background-color:pink;
        li {
          position: absolute;
          bottom: -30px;
          height: 30px;
          width: 40px;
          opacity: 1;
        }
        li:nth-child(1) {
          animation: flying 4s ease-out infinite;
        }
        li:nth-child(2) {
          color: #e76f51;
          animation: flying 4s ease-out infinite;
          animation-delay: 0.6s;
        }
        li:nth-child(3) {
          color: #f4a261;
          animation: flying 4s ease-out infinite;
          animation-delay: 1.2s;
        }
        li:nth-child(4) {
          color: #e9c46a;
          animation: flying 4s ease-out infinite;
          animation-delay: 1.8s;
        }
        li:nth-child(5) {
          color: #2a9d8f;
          animation: flying 4s ease-out infinite;
          animation-delay: 2.4s;
        }
      }
    }
  }
  // 旁白
  .asideBox {
    background-color: #000000b8;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px !important;
    zoom: 1 !important;
    min-height: 41px;
    width: 100%;
    color: #fff;
    padding: 10px;
    padding-right: 40px;
    box-sizing: border-box;
    border-radius: 8px 0 8px 0px;
  }
  .topBox {
    background-color: #000000b8;
    position: absolute;
    top: 0;
    color: #fff;
    width: 100%;
    left: 0;
    padding: 10px;
    padding-right: 40px;
    box-sizing: border-box;
    border-radius: 8px 0 8px 0px;
    min-height: 41px;
  }
}
// 醒来后的视图
.wakeUp {
  height: 97% !important;
  width: 97% !important;
  margin: 0 auto;
  .mainRole {
    left: 32vw;
    bottom: 28vh;
    transform: scale(0.6);
  }
}
@keyframes flying {
  0% {
    bottom: -30px;
    left: 30px;
    opacity: 1;
    font-size: 14px;
  }
  50% {
    left: 0px;
  }
  100% {
    bottom: 170px;
    left: 30px;
    opacity: 0;
    font-size: 50px;
  }
}
// 1920 主角
.mainRole {
  position: absolute;
  left: 32vw;
  bottom: 30vh;
  transform: scale(0.7) scaleX(1);
  opacity: 1;
  z-index: 60;
  transition: 0.8s ease-out 0s all;
}
.cleanAnimation {
  animation: clean 8s ease-out 0s 1 alternate forwards;
  animation-fill-mode: forwards;
}
@media screen and (max-width: 570px) {
  .mainRole {
    position: absolute;
    left: 30vw;
    bottom: 28vh;
    transform: scale(0.7) scaleX(1);
    opacity: 1;
    z-index: 60;
    transition: 0.8s ease-out 0s all;
  }
  .cleanAnimation {
    animation: clean2 8s ease-out 0s 1 alternate forwards;
    animation-fill-mode: forwards;
  }
}
.hiding {
  left: 27vw;
  bottom: 28vh;
  overflow: hidden;
}
.goaway {
  animation: goaway 8s ease-out 0s 1 alternate forwards;
  animation-fill-mode: forwards;
}
@keyframes goaway {
  40% {
    left: 32vw;
    opacity: 1;
    bottom: -5vh;
    transform: scale(0.85);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: -5vh;
    transform: scale(0.85);
    left: 93vw;
  }
}
@keyframes clean {
  0% {
    left: 32vw;
    bottom: 30vh;
    transform: scale(0.7);
    opacity: 0;
  }
  1% {
    left: 32vw;
    bottom: 28vh;
    transform: scale(0.7);
    opacity: 0;
  }
  2% {
    left: 55vw;
    bottom: 6vh;
    transform: scale(0.9) scaleX(-1);
    opacity: 0;
  }
  4% {
    left: 55vw;
    bottom: 6vh;
    transform: scale(0.9) scaleX(-1);
    opacity: 1;
  }
  20% {
    left: 55vw;
    bottom: 6vh;
    transform: scale(0.9) scaleX(-1);
    opacity: 1;
  }
  22% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    left: 50vw;
    bottom: 28vh;
    transform: scale(0.7) scaleX(1);
  }
  35% {
    opacity: 1;
    left: 50vw;
    bottom: 28vh;
    transform: scale(0.7);
    transform: scale(0.7) scaleX(1);
  }
  55% {
    opacity: 1;
    left: 50vw;
    bottom: 28vh;
    transform: scale(0.7);
    transform: scale(0.7) scaleX(1);
  }
  57% {
    opacity: 0;
    left: 52vw;
    bottom: 28vh;
    transform: scale(0.7) scaleX(-1);
  }
  60% {
    opacity: 0;
    left: 34vw;
    bottom: 30vh;
    transform: scale(0.7) scaleX(-1);
  }
  65% {
    opacity: 1;
    left: 34vw;
    bottom: 30vh;
    transform: scale(0.7) scaleX(-1);
  }
  99% {
    opacity: 1;
    left: 34vw;
    bottom: 30vh;
    transform: scale(0.7) scaleX(-1);
  }
  100% {
    opacity: 1;
    left: 32vw;
    bottom: 30vh;
    transform: scale(0.7) scaleX(-1);
  }
}
// 小屏幕 iphone 5 se 兼容动画
@keyframes clean2 {
  0% {
    left: 30vw;
    bottom: 28vh;
    transform: scale(0.7);
    opacity: 0;
  }
  1% {
    left: 30vw;
    bottom: 28vh;
    transform: scale(0.7);
    opacity: 0;
  }
  2% {
    left: 50vw;
    bottom: 6vh;
    transform: scale(0.9) scaleX(-1);
    opacity: 0;
  }
  4% {
    left: 50vw;
    bottom: 6vh;
    transform: scale(0.9) scaleX(-1);
    opacity: 1;
  }
  20% {
    left: 50vw;
    bottom: 6vh;
    transform: scale(0.9) scaleX(-1);
    opacity: 1;
  }
  22% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    left: 47vw;
    bottom: 28vh;
    transform: scale(0.7) scaleX(1);
  }
  35% {
    opacity: 1;
    left: 47vw;
    bottom: 28vh;
    transform: scale(0.7);
    transform: scale(0.7) scaleX(1);
  }
  55% {
    opacity: 1;
    left: 47vw;
    bottom: 28vh;
    transform: scale(0.7);
    transform: scale(0.7) scaleX(1);
  }
  57% {
    opacity: 0;
    left: 47vw;
    bottom: 29vh;
    transform: scale(0.7) scaleX(-1);
  }
  60% {
    opacity: 0;
    left: 30vw;
    bottom: 29vh;
    transform: scale(0.7) scaleX(-1);
  }
  65% {
    opacity: 1;
    left: 30vw;
    bottom: 29vh;
    transform: scale(0.7) scaleX(-1);
  }
  100% {
    opacity: 1;
    left: 29vw;
    bottom: 30vh;
    transform: scale(0.7) scaleX(-1);
  }
}

//眨眼动画
.eyeCover {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  .top {
    position: absolute;
    height: 52%;
    width: 100%;
    top: -50%;
    background-color: #000;
  }
  .bottom {
    position: absolute;
    height: 50%;
    width: 100%;
    bottom: -50%;
    background-color: #000;
  }
}
.eyesMoving {
  animation: beingDark 8s ease-in 0s 1 alternate forwards;
  .top {
    animation: eyesMoveUp 8s ease-in 0s 1 alternate forwards;
  }
  .bottom {
    animation: eyesMoveDown 8s ease-in 0s 1 alternate forwards;
  }
}

@keyframes eyesMoveUp {
  0% {
    top: -50%;
  }
  20% {
    top: 0%;
  }
  25% {
    top: -50%;
  }
  45% {
    top: 0%;
  }
  50% {
    top: -50%;
  }
  100% {
    top: 0%;
  }
}
@keyframes eyesMoveDown {
  0% {
    bottom: -50%;
  }
  20% {
    bottom: 0%;
  }
  25% {
    bottom: -50%;
  }
  45% {
    bottom: 0%;
  }
  50% {
    bottom: -50%;
  }
  100% {
    bottom: 0%;
  }
}
@keyframes beingDark {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgb(0, 0, 0);
  }
}
// 过渡
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.arrow {
  position: absolute;
  height: 50px;
  width: 50px;
  right: 10px;
  bottom: 10px;
  z-index: 100;
  line-height: 50px;
  text-align: center;
  animation: shaking 1s ease-in infinite;
}
@keyframes shaking {
  0% {
    right: 5px;
  }
  50% {
    right: 15px;
  }
  100% {
    right: 5px;
  }
}
.changePage {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 102;
  height: 100%;
  width: 100%;
}
// 纸条层
.things {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9;
  height: 100%;
  width: 100%;
  perspective: 800px;
  transform-style: preserve-3d;
  .paper {
    position: absolute;
    display: inline-block;
    width: 22px;
    height: 40px;
    background-color: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 1px;
    transform: rotateX(62deg) rotateY(358deg) rotateZ(347deg);
    overflow: hidden;
  }
  .paper:nth-child(1) {
    left: 55%;
    top: 52%;
  }
  .paper:nth-child(2) {
    width: 14px;
    height: 24px;
    left: 37%;
    top: 54%;
    transform: rotateX(58deg) rotateY(3deg) rotateZ(15deg);
  }
  .paper:nth-child(3) {
    top: 80%;
    right: 31%;
    width: 20px;
    height: 34px;
  }
  .paper:nth-child(4) {
    left: 46%;
    top: 52%;
    width: 19px;
    height: 37px;
    transform: rotateX(62deg) rotateY(358deg) rotateZ(14deg);
  }
  .noisy {
    position: absolute;
    padding: 10px;
    border-radius: 9px;
    width: 70px;
    top: 17%;
    right: 20%;
    transform-origin: 100% 0;
    animation: bling .8s ease-out .3s 5 forwards;
    img {
      width: 100%;
    }
    @keyframes bling {
      from {
        opacity: 1;
        top: 16%;
        right: 19%;
      }
      to {
        top: 16%;
        right: 25%;
        opacity: 1;
      }
    }
  }
}
.coverBox {
  position: absolute;
  left: 0;
  top: 0;
  perspective-origin: 800px;
  height: 100%;
  transform-style: preserve-3d;
  width: 100%;
}
//提示牌
.banner {
  padding: 8px;
  position: absolute;
  right: 17px;
  bottom: 02px;
  height: 180px;
  width: 120px;
  // line-height: 100px;
  background: url(https://suezp.cn/server//banner.png) no-repeat;
  background-size: cover;
  border-radius: 8px;
  text-align: center;
}
.asideModal {
  position: absolute;
  font-size: 12px;
  left: 27%;
  top: 19%;
  height: 6.2rem;
  width: 11rem;
  padding: 21px 17px 0 24px;
  background: url(https://suezp.cn/server//dialog_1.png) no-repeat;
  box-sizing: border-box;
  background-size: cover;
  text-indent: 2rem;
}
</style>
