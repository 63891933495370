<template>
  <div :class="['role', { hide: hide }]" @click="click">
    <div
      :style="{ transform: 'scaleX(' + scaleX + ')' }"
      :class="[{ moving: moving }, { cleanMoving: cleanMoving }]"
    >
      <template v-if="!isClean">
        <img v-for="src in srcList" :key="src" :src="imgUrl + src" alt="" />
      </template>
      <template v-else>
        <div class="cleanRole">
          <img
            v-for="(src, index) in cleanList"
            :key="index"
            :src="imgUrl + src"
            alt=""
          />
        </div>
      </template>
      <div :class="['modal', { showModal: modal }]">
        <!-- <div class="modalHead">
          <img
            src="https://suezp.cn/server//mainRole/head.png"
            style="height: 60px; width: 60px"
            alt="头像"
          />
        </div> -->
        <p ref="contentP">
          <span ref="contentSpan" style="display: block">{{ showWords }}</span>
        </p>
        <span v-if="nextArrow" class="nextArrow">→</span>
      </div>
      <div class="exclamation" v-if="exclamation">❗</div>
      <div class="mainRoleNoisy" v-if="scare">
        <img src="https://suezp.cn/server//noisy.png" alt="嘈杂声" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MainRole",
  props: {
    scaleX: Number,
    modal: Boolean,
    moving: Boolean,
    words: String,
    cleanMoving: Boolean,
    isClean: Boolean,
    hide: Boolean,
    nextArrow: Boolean,
    exclamation: Boolean,
    scare: Boolean,
  },
  data() {
    return {
      imgUrl: "https://suezp.cn/server//",
      showWords: "",
      srcList: [
        "mainRole/head.png",
        "mainRole/body.png",
        "mainRole/arm1.png",
        "mainRole/arm2.png",
        "mainRole/leg2.png",
        "mainRole/leg1.png",
      ],
      cleanList: [
        "mainRole/head.png",
        "mainRole/body.png",
        "mainRole/arm1.png",
        "mainRole/arm1.png",
        "mainRole/leg2.png",
        "mainRole/leg1.png",
      ],
      timer: null,
    };
  },
  mounted() {},
  methods: {
    say() {
      this.showWords = "";
      let arr = [...this.words];
      let index = 0;
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = setInterval(() => {
        this.showWords += arr[index];
        index++;
        this.$refs.contentP.scrollTop = this.$refs.contentSpan.clientHeight;
        if (index >= arr.length) {
          this.$refs.contentP.scrollTop = this.$refs.contentSpan.clientHeight;
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 150);
    },
    click() {
      this.$emit("click", true);
    },
  },
};
</script>
<style lang="less" scoped>
.role {
  height: 150px !important;
  width: 150px !important;
  position: relative;
  left: 0;
  opacity: 1;
  text-align: left;
  transition: 1.5s ease-out 0.5s all;
  div {
    height: 100%;
    width: 100%;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.8s ease-out 0s all;
    animation-fill-mode: forwards;
  }
  img:nth-child(2) {
    z-index: 100;
  }
  img:nth-child(3) {
    top: 90px;
    right: 48px;
    height: 19px;
    width: 27px;
    transform-origin: 0% 0%;
    transform: rotate(0deg);
  }
  img:nth-child(4) {
    top: 88px;
    left: 43px;
    height: 20px;
    z-index: 110;
    width: 28.5px;
    transform-origin: 78% 43%;
    transform: rotate(0deg);
  }
  img:nth-child(5) {
    bottom: 5px;
    left: 43px;
    height: 37px;
    width: 31.5px;
    transform-origin: 91% 12%;
    transform: rotate(-19deg);
  }
  img:nth-child(6) {
    height: 37px;
    width: 31.5px;
    bottom: 5px;
    right: 59px;
    transform-origin: 5% 18%;
    transform: rotate(12deg);
  }
  .moving {
    img:nth-child(1) {
      animation: headMove 1.5s infinite;
    }
    img:nth-child(2) {
      z-index: 10;
    }
    img:nth-child(3) {
      animation: arm1Move 1.5s infinite;
    }
    img:nth-child(4) {
      animation: arm2Move 1.5s infinite;
    }
    img:nth-child(5) {
      animation: leg2Move 1.5s infinite;
    }
    img:nth-child(6) {
      animation: leg1Move 1.5s infinite;
    }
  }
  // 打扫会议室动画
  .cleanRole {
    img:nth-child(4) {
      top: 90px;
      left: 65px;
      height: 20px;
      z-index: 110;
      width: 28.5px;
      transform-origin: 0% 0%;
      transform: rotate(0deg);
    }
  }
  .cleanMoving {
    img:nth-child(1) {
      animation: headMove 1.5s infinite;
    }
    img:nth-child(2) {
      z-index: 10;
    }
    img:nth-child(3) {
      animation: arm1Clean 0.8s infinite;
    }
    img:nth-child(4) {
      animation: arm2Clean 0.8s infinite;
    }
  }
  @keyframes headMove {
    0% {
      transform: rotate(-2deg);
    }
    50% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(-2deg);
    }
  }
  @keyframes leg1Move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      bottom: 10px;
      transform: rotate(70deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes leg2Move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      bottom: 7px;
      transform: rotate(-70deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes arm1Move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes arm2Move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-120deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes arm1Clean {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes arm2Clean {
    0% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(-5deg);
    }
  }

  // modal 对话框
  .modal {
    left: -40%;
    top: 2rem;
    opacity: 0;
    position: absolute;
    box-sizing: border-box;
    font-size: 0.9rem;
    padding: 12px 21px;
    height: 7rem;
    width: 12rem;
    background: url(https://suezp.cn/server//dialog_1.png) no-repeat 100% 100%;
    background-size: cover;
    overflow: hidden;
    z-index: 20;
    text-align: center;
    transition: 0.5s cubic-bezier(0.74, 0.2, 0.25, 0.69) 0s all;
    transform-origin: center center;
    transform: rotateZ(-2deg) rotateY(2deg) rotateX(10deg);
    text-indent: 2rem;
    padding-top: 26px;
    font-family: "楷体", "楷体_GB2312";
    p {
      width: 98%;
      margin: 0 auto;
      height: 3.4rem;
      overflow-y: scroll;
    }
    .modalHead {
      position: absolute;
      left: -5%;
      top: 5px;
      height: 37px;
      width: 50px;
    }
  }
  .showModal {
    opacity: 1;
    left: 1rem;
    top: -7rem;
    height: 7rem;
    width: 12rem;
    // animation: modalMove 2s 0.5s infinite;
    text-indent: 2rem;
    padding-top: 26px;
  }
  @keyframes modalMove {
    0% {
      transform: rotateZ(-1deg) rotateY(2deg) rotateX(5deg);
    }
    50% {
      transform: rotateZ(1deg) rotateY(0deg) rotateX(0deg);
    }
    100% {
      transform: rotateZ(-1deg) rotateY(2deg) rotateX(5deg);
    }
  }
  .nextArrow {
    position: absolute;
    left: 7.5rem;
    top: 4.1rem;
    animation: blink 0.5s ease-out infinite alternate forwards;
  }
  @keyframes blink {
    from {
      left: 7.3rem;
    }
    to {
      left: 7.7rem;
    }
  }
}
.hide {
  left: -160px;
  opacity: 0;
}
.exclamation {
  position: absolute;
  top: -18%;
  left: 74%;
  display: inline-block;
  width: 10px !important;
  height: 23px !important;
  color: red;
  zoom: 2;
  z-index: 30;
  animation: exclamationShaking 0.5s ease-out infinite alternate forwards;
}
@keyframes exclamationShaking {
  from {
    transform: rotate(-10deg) scale(0.9);
  }
  to {
    transform: rotate(10deg) scale(1.3);
  }
}
.mainRoleNoisy {
  position: absolute;
  top: 7%;
  left: 82%;
  display: inline-block;
  height: 20px !important;
  width: 20px !important;
  transform: scale(-1);
  transform: rotate(163deg);
  color: red;
  zoom: 2;
  z-index: 30;
  opacity: 0;
  animation: fade 0.8s ease-out .5s 1 alternate forwards;
  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>