<template>
  <div class="page">
    <div
      :class="['motoDiv', { rush: isdone }, { rollingMove: canRolling }]"
      :style="{ left: 20 + progressNum / 1.7 + '%' }"
    >
      <img class="moto" src="https://suezp.cn/server//moto.png" alt="车架" />
      <span class="circle circle1" >
        <img
          src="https://suezp.cn/server//circle.png"
          alt="车轮"
        />
      </span>
      <span class="circle circle2" >
        <img
          src="https://suezp.cn/server//circle.png"
          alt="车轮"
        />
      </span>
      <img src="https://suezp.cn/server//gas.png" alt="尾气" class="gas" />
    </div>
    <div class="outsideBox">
      <div class="insideBox" :style="{ width: progressNum + '%' }"></div>
    </div>
    <div class="progressNum">
      {{
        progressNum == 100 ? "加载完成" : "拼命加载中......" + progressNum + "%"
      }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Loading",
  props: {
    progress: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      showNum: false,
      progressNum: 0,
      timer: null,
      isdone: false,
      canRolling: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showNum = true;
      this.countDown();
    }, 0);
    setTimeout(() => {
      this.canRolling = true;
    }, 2000);
  },
  methods: {
    countDown() {
      this.timer = setInterval(() => {
        if (this.progressNum == 100) {
          clearInterval(this.timer);
          this.timer = null;
          this.isdone = true;
          this.$emit("done", true);
          return;
        }
        if (this.progress == 100) {
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            if (this.progressNum == 100) {
              clearInterval(this.timer);
              this.timer = null;
              this.isdone = true;
              this.showNum = false;
              setTimeout(() => {
                this.$emit("done", true);
              }, 500);
              return;
            }
            this.progressNum += 1;
          }, 100);
        }
        if (this.progressNum >= this.progress) {
          this.progressNum = this.progress;
          return;
        } else {
          this.progressNum++;
          console.log(this.progressNum);
        }
      }, 500);
    },
  },
};
</script>
<style scoped lang="less">
.page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgb(253, 227, 197);
  position: relative;
  box-sizing: border-box;
}
.motoDiv {
  left: 0%;
  position: relative;
  height: 140px;
  width: 135px;
  transition: all 0.5s ease-in;
  margin-top: 10%;
  // transform: translateY(-50%);
  zoom: 0.5;
  .moto {
    position: absolute;
    width: 100%;
    z-index: 5;
  }
  .circle {
    position: absolute;
    height: 40px;
    width: 40px;
    z-index: 1;
    top: 78%;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .circle1 {
    bottom: -10px;
    right: 7px;
    animation: rolling 2s linear infinite forwards;
  }
  .circle2 {
    bottom: -10px;
    left: 8px;
    animation: rolling 2s linear 1s infinite forwards;
  }
  .gas {
    width: 20px;
    position: absolute;
    z-index: 10;
    bottom: 4px;
    left: -16px;
    animation: gasMove 0.8s linear 0s infinite forwards;
  }
}
.rush {
  left: 200% !important;
  opacity: 0;
}
@keyframes gasMove {
  0% {
    left: -16px;
    bottom: 4px;
  }
  100% {
    left: -26px;
    bottom: 10px;
    opacity: 0;
  }
}
@keyframes rolling {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(720deg);
  }
}
.outsideBox {
  position: relative;
  height: 10px;
  width: 50%;
  margin: 15px auto 5px;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: rgb(204, 204, 204);
  .insideBox {
    height: 100%;
    width: 20%;
    transition: all 0.1s linear;
    background-color: rgb(91, 192, 105);
  }
}
.progressNum {
  font-size: 14px;
  color: #000;
  // margin-top: 10px;
  opacity: 1;
  text-align: center;
  transform: 0s ease-int 1.5s all;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>