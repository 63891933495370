<template>
  <div class="page" @click="click">
    <div>
      <img
        :src="imgUrl"
        :style="humanStyle"
        :class="{ moving: moving }"
        alt=""
      />
      <div
        :class="['modal', { showModal: modal }]"
        :style="{ left: modalLeft }"
      >
        <p ref="contentP">
          <span ref="contentSpan" style="display:block;">{{ showWords }}</span>
        </p>
        <span v-if="nextArrow" class="nextArrow">→</span>
      </div>
      <div v-if="clickArrow">
        <span class="humanArrow"> 👇 </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CommonHuman",
  props: {
    scaleX: Number,
    modal: Boolean,
    moving: Boolean,
    words: String,
    nextArrow: Boolean,
    imgUrl: String,
    clickArrow: Boolean,
    modalScale: Number,
    modalLeft: String,
    humanStyle: String,
  },
  data() {
    return {
      showWords: "",
    };
  },
  methods: {
    say() {
      this.showWords = "";
      let arr = [...this.words];
      let index = 0;
      let timer = setInterval(() => {
        this.showWords += arr[index];
        index++;
        this.$refs.contentP.scrollTop = this.$refs.contentSpan.clientHeight;
        if (index >= arr.length) {
          this.$refs.contentP.scrollTop = this.$refs.contentSpan.clientHeight;
          clearInterval(timer);
        }
      }, 150);
    },
    click() {
      this.$emit("click", true);
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  height: 180px;
  width: 150px;
  position: relative;
  div {
    height: 100%;
    width: 100%;
  }
  img {
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.8s ease-out 0s all;
  }
  .moving {
    animation: humanShaking 0.4s ease-in infinite alternate;
  }
  @keyframes humanShaking {
    from {
      bottom: 2px;
    }
    to {
      bottom: 0px;
    }
  }
  // 点击提示
  .humanArrow {
    position: absolute;
    height: 40px;
    width: 40px;
    text-align: center;
    top: -20%;
    right: 35%;
    z-index: 120;
    zoom: 1.5;
    animation: humanArrow 0.5s ease-in infinite alternate;
  }
  @keyframes humanArrow {
    from {
      top: -22%;
    }
    to {
      top: -18%;
    }
  }
  // modal 对话框
  .modal {
    left: -10%;
    top: 4rem;
    opacity: 0;
    position: absolute;
    box-sizing: border-box;
    font-size: 0.8rem;
    padding: 12px 21px;
    height: 7rem;
    width: 12rem;
    background: url(https://suezp.cn/server//dialog_1.png) no-repeat 100% 100%;
    background-size: cover;
    overflow: hidden;
    z-index: 20;
    text-align: center;
    transition: 0.5s cubic-bezier(0.74, 0.2, 0.25, 0.69) 0s all;
    transform-origin: center center;
    transform: rotateZ(-2deg) rotateY(2deg) rotateX(10deg) scaleX(-1);
    transform: scaleY(1);
    text-indent: 2rem;
    padding-top: 26px;
    p {
      width: 98%;
      margin: 0 auto;
      height: 3.4rem;
      overflow-y: scroll;
    }
    .modalHead {
      position: absolute;
      left: -5%;
      top: 5px;
      height: 37px;
      width: 50px;
    }
  }
  .showModal {
    opacity: 1;
    top: -5.5rem;
    height: 7rem;
    width: 12rem;
    // animation: modalMove 2s 0.5s infinite;
    text-indent: 2rem;
    padding-top: 26px;
  }
  @keyframes modalMove {
    0% {
      transform: rotateZ(-1deg) rotateY(2deg) rotateX(5deg) scaleX(-1);
    }
    50% {
      transform: rotateZ(1deg) rotateY(0deg) rotateX(0deg) scaleX(-1);
    }
    100% {
      transform: rotateZ(-1deg) rotateY(2deg) rotateX(5deg) scaleX(-1);
    }
  }
  .nextArrow {
    position: absolute;
    left: 7.5rem;
    top: 4.1rem;
    animation: blink 0.5s ease-out infinite alternate forwards;
  }
  @keyframes blink {
    from {
      left: 7.3rem;
    }
    to {
      left: 7.7rem;
    }
  }
}
</style>
