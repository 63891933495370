<template>
  <div class="recent">
    <div
      :style="{ transform: 'scaleX(' + scaleX + ')' }"
      :class="{ moving: moving }"
    >
      <img v-for="src in srcList" :key="src" :src="imgUrl + src" alt="" />
      <div :class="['modal', { showModal: modal }]">
        <p ref="contentP">
          <span ref="contentSpan" style="display:block;">{{ showWords }}</span>
        </p>
        <span v-if="nextArrow" class="nextArrow">→</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RecentRole",
  props: {
    scaleX: Number,
    modal: Boolean,
    moving: Boolean,
    words: String,
    nextArrow: Boolean
  },
  data() {
    return {
      imgUrl: "https://suezp.cn/server//",
      showWords: "",
      srcList: [
        "recentRole/body.png",
        "recentRole/arm1.png",
        "recentRole/arm2.png",
        "recentRole/leg2.png",
        "recentRole/leg1.png",
      ],
    };
  },
  methods: {
    say() {
      this.showWords = "";
      let arr = [...this.words];
      let index = 0;
      let timer = setInterval(() => {
        this.showWords += arr[index];
        index++;
        this.$refs.contentP.scrollTop = this.$refs.contentSpan.clientHeight;
        if (index >= arr.length) {
          this.$refs.contentP.scrollTop = this.$refs.contentSpan.clientHeight;
          clearInterval(timer);
        }
      }, 150);
    },
  },
};
</script>
<style lang="less" scoped>
.recent {
  height: 150px;
  width: 150px;
  position: relative;
  div {
    height: 100%;
    width: 100%;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.8s ease-out 0s all;
  }
  img:nth-child(1) {
    z-index: 10;
    left: 0;
  }
  img:nth-child(2) {
    top: 89px;
    right: 49px;
    height: 24px;
    width: 32px;
    transform-origin: 0% 0%;
    transform: rotate(0deg);
  }
  img:nth-child(3) {
    top: 83.5px;
    left: 30px;
    height: 25px;
    z-index: 30;
    width: 33.5px;
    transform-origin: 78% 43%;
    transform: rotate(0deg);
  }
  img:nth-child(4) {
    bottom: 7px;
    left: 27px;
    height: 37px;
    width: 31.5px;
    transform-origin: 91% 12%;
    transform: rotate(-23deg);
  }
  img:nth-child(5) {
    height: 37px;
    width: 31.5px;
    bottom: 2px;
    right: 69px;
    transform-origin: 5% 18%;
    transform: rotate(10deg);
  }
  .moving {
    img:nth-child(1) {
      animation: headMove 1.5s infinite;
    }
    img:nth-child(2) {
      animation: arm1Move 1.5s infinite;
    }
    img:nth-child(3) {
      animation: arm2Move 1.5s infinite;
    }
    img:nth-child(4) {
      animation: leg2Move 1.5s infinite;
    }
    img:nth-child(5) {
      animation: leg1Move 1.5s infinite;
    }
  }
  @keyframes headMove {
    0% {
      transform: rotate(-2deg);
    }
    50% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(-2deg);
    }
  }
  @keyframes leg1Move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      bottom: 10px;
      transform: rotate(70deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes leg2Move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      bottom: 7px;
      transform: rotate(-70deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes arm1Move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes arm2Move {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-120deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  // modal 对话框
  .modal {
    left: -40%;
    top: 4rem;
    opacity: 0;
    position: absolute;
    box-sizing: border-box;
    font-size: 0.9rem;
    padding: 12px 21px;
    height: 7rem;
    width: 12rem;
    background: url(https://suezp.cn/server//dialog_1.png) no-repeat 100% 100%;
    background-size: cover;
    overflow: hidden;
    z-index: 20;
    text-align: center;
    transition: 0.5s cubic-bezier(0.74, 0.2, 0.25, 0.69) 0s all;
    transform-origin: center center;
    transform: rotateZ(-2deg) rotateY(2deg) rotateX(10deg) scaleX(-1);
    text-indent: 2rem;
    padding-top: 26px;
    p {
      width: 98%;
      margin: 0 auto;
      height: 3.4rem;
      overflow-y: scroll;
    }
  }
  .showModal {
    opacity: 1;
    left: -6rem;
    top: -7rem;
    height: 7rem;
    width: 12rem;
    // animation: modalMove 2s 0.5s infinite;
    text-indent: 2rem;
    padding-top: 26px;
  }
  @keyframes modalMove {
    0% {
      transform: rotateZ(-1deg) rotateY(2deg) rotateX(5deg) scaleX(-1);
    }
    50% {
      transform: rotateZ(1deg) rotateY(0deg) rotateX(0deg) scaleX(-1);
    }
    100% {
      transform: rotateZ(-1deg) rotateY(2deg) rotateX(5deg) scaleX(-1);
    }
  }
  .nextArrow {
    position: absolute;
    left: 7.5rem;
    top: 4.1rem;
    animation: blink 0.5s ease-out infinite alternate forwards;
  }
  @keyframes blink {
    from {
      left: 7.3rem;
    }
    to {
      left: 7.7rem;
    }
  }
}
</style>